import * as React from 'react';
import * as S from './DashboardCountriesSelect.styles';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {ArrowRightOutlined} from '@ant-design/icons';
import FactoryIcon from '@mui/icons-material/Factory';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

export function DashboardCountriesSelect() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = React.useState<string>('');
  const [selectedCalculator, setSelectedCalculator] = React.useState<string>('');

  const goToCalculator = (calculator: string) => {
    switch (calculator) {
      case 'industry':
        navigate(`${process.env.REACT_APP_ROUTE_INDUSTRY_CALCULATOR}/${selectedCountry}`);
        break;
      case 'households':
        navigate(`${process.env.REACT_APP_ROUTE_HOUSEHOLDS_CALCULATOR}/${selectedCountry}`);
        break;
    }
  };

  const countrySelect = (
    <S.Container>
      <S.Card3 onClick={() => setSelectedCountry('global')}>
        {/* <img src="https://flagsapi.com/RS/flat/64.png" /> */}

        <S.H3>{t('countries.global')}</S.H3>

        <S.P>
          <Trans i18nKey="dashboard.countries.globalCalculatorDescription" components={{bold: <b />}} />
        </S.P>

        <S.GoCorner>
          <S.GoArrow className="go-arrow">
            <ArrowRightOutlined />
          </S.GoArrow>
        </S.GoCorner>
      </S.Card3>

      <S.Card3 onClick={() => setSelectedCountry('rs')}>
        <img src="https://flagsapi.com/RS/flat/64.png" />

        <S.H3>{t('countries.serbia')}</S.H3>

        <S.P>
          <Trans i18nKey="dashboard.countries.serbiaCalulatorDescription" components={{bold: <b />}} />
        </S.P>

        <S.GoCorner>
          <S.GoArrow className="go-arrow">
            <ArrowRightOutlined />
          </S.GoArrow>
        </S.GoCorner>
      </S.Card3>
    </S.Container>
  );

  const calculatorSelect = (
    <S.Container>
      <S.Card3 onClick={() => goToCalculator('households')}>
        <MapsHomeWorkIcon style={{fontSize: 60, color: '#00838d'}} />

        <S.H3>{t('dashboard.calculators.householdsTitle')}</S.H3>

        <S.P>
          <Trans i18nKey="dashboard.calculators.householdsDescription" components={{bold: <b />}} />
        </S.P>

        <S.GoCorner>
          <S.GoArrow className="go-arrow">
            <ArrowRightOutlined />
          </S.GoArrow>
        </S.GoCorner>
      </S.Card3>

      <S.Card3 onClick={() => goToCalculator('industry')}>
        <FactoryIcon style={{fontSize: 60, color: '#00838d'}} />

        <S.H3>{t('dashboard.calculators.industryTitle')}</S.H3>

        <S.P>
          <Trans i18nKey="dashboard.calculators.industryDescription" components={{bold: <b />}} />
        </S.P>

        <S.GoCorner>
          <S.GoArrow className="go-arrow">
            <ArrowRightOutlined />
          </S.GoArrow>
        </S.GoCorner>
      </S.Card3>
    </S.Container>
  );

  return <>{selectedCountry === '' ? countrySelect : calculatorSelect}</>;
}
