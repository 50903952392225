import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import NumericInput from '../common/inputs/NumericInput/NumericInput';

interface IIndustryElectricityDataInputProps {
  edit?: boolean;
}

export const IndustryElectricityDataInput: React.FC<IIndustryElectricityDataInputProps> = () => {
  const {t} = useTranslation();

  const [basePriceOfElectricity, setBasePriceOfElectricity] = React.useState<string>('');
  const [gridFee, setGridFee] = React.useState<string>('');
  const [priceOfExportedElectricity, setPriceOfExportedElectricity] = React.useState<string>('');

  return (
    <CalculatorsInputCard
      id="electricityConsumption"
      title={t('calculators.inputData.industryElectricityData.title')}
      description={t('calculators.inputData.industryElectricityData.description')}
    >
      <BaseButtonsForm.Item
        name="basePriceOfElectricity"
        label={t('calculators.inputData.industryElectricityData.basePriceOfElectricity')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <NumericInput min={0} suffix="EUR / MWh" value={basePriceOfElectricity} onChange={setBasePriceOfElectricity} />
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item
        name="additionalGridFeesAndTaxes"
        label={t('calculators.inputData.industryElectricityData.additionalGridFeesAndTaxes')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <NumericInput min={0} max={100} suffix="%" value={gridFee} onChange={setGridFee} />
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item
        name="sellingPriceOfElectricity"
        label={t('calculators.inputData.industryElectricityData.sellingPriceOfElectricity')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <NumericInput
          min={0}
          suffix="EUR / MWh"
          value={priceOfExportedElectricity}
          onChange={setPriceOfExportedElectricity}
        />
      </BaseButtonsForm.Item>
    </CalculatorsInputCard>
  );
};
