import {httpLinkProApi} from './httpLinkPro.api';

export interface OpenProjectDocumentModel {
  documentId: string;
  documentType: string;
  documentPath: string;
}

export interface OpenProjectModel {
  projectId: number;
  plantCapacity: number; // Changed to number to represent a decimal
  location: string;
  roofStructure: string;
  investmentTimeline: string;
  investor: string;
  documents: OpenProjectDocumentModel[];
}

export const getAllOpenProjects = (): Promise<OpenProjectModel[]> =>
  httpLinkProApi.get<any>(process.env.REACT_APP_LINK_PRO_API_OPEN_PROJECT!).then(({data}) => data);

export const getSingleOpenProject = (offerId: string): Promise<OpenProjectModel> =>
  httpLinkProApi.get<any>(`${process.env.REACT_APP_LINK_PRO_API_OPEN_PROJECT!}/${offerId}`).then(({data}) => data);
