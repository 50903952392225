import {RSHouseholdRequest} from '@app/domain/RSHouseholdRequestModel';
import {httpApi} from './http.api';
import {RSHouseholdResponseModel} from '@app/domain/RSHouseholdResponseModel';
import {
  eExcelReportCalculationTypeOptions,
  eExcelReportCountryOptions,
  eExcelReportLanguageOptions,
} from '@app/interfaces/enums';
import {HouseholdGlobalRequestModel} from '@app/domain/HouseholdRequestModel';

export const calculateHouseholdDataForRS = (householdData: RSHouseholdRequest): Promise<RSHouseholdResponseModel> =>
  httpApi
    .post<RSHouseholdResponseModel>(process.env.REACT_APP_API_HOUSEHOLDS_RS!, householdData)
    .then(({data}) => data);

export const calculateHouseholdDataGlobal = (householdData: HouseholdGlobalRequestModel): Promise<any> =>
  httpApi.post<any>(process.env.REACT_APP_API_HOUSEHOLDS_GLOBAL!, householdData).then(({data}) => data);

interface GenerateExcelReportRequest {
  metaData: {
    country: eExcelReportCountryOptions;
    language: eExcelReportLanguageOptions;
    calculationType: eExcelReportCalculationTypeOptions;
  };
  calculationData: any;
}

export const generateExcelReportRS = (calculationData: any, language: eExcelReportLanguageOptions): Promise<URL> =>
  httpApi
    .post(process.env.REACT_APP_API_GENERATE_EXCEL_HOUSEHOLDS!, {
      metaData: {
        country: eExcelReportCountryOptions.RS,
        language: language,
        calculationType: eExcelReportCalculationTypeOptions.HOUSEHOLDS,
      },
      calculationData: calculationData,
    })
    .then(({data}) => data);

export const generateExcelReportGlobal = (calculationData: any, language: eExcelReportLanguageOptions): Promise<URL> =>
  httpApi
    .post(process.env.REACT_APP_API_GENERATE_EXCEL_HOUSEHOLDS!, {
      metaData: {
        country: eExcelReportCountryOptions.GLOBAL,
        language: language,
        calculationType: eExcelReportCalculationTypeOptions.HOUSEHOLDS,
      },
      calculationData: calculationData,
    })
    .then(({data}) => data);
