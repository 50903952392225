import styled from 'styled-components';
import {BaseCard as CommonCard} from '@app/components/common/BaseCard/BaseCard';
import {FundProjectionScreenOutlined} from '@ant-design/icons';
import HouseIcon from '@mui/icons-material/House';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
  cursor: pointer;

  .ant-card-body {
    padding: 25px;
  }
  W &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
`;

export const CardHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
`;

export const OpenProjectIcon = styled(FundProjectionScreenOutlined)`
  padding: 17px;
  border-radius: 50%;
  font-size: 50px;
  color: black;
  background: #e3e4e8;
`;

export const RoofStructureIcon = styled(HouseIcon)`
  font-size: 30px !important;
  color: black;
`;

export const InvestmentTimelineIcon = styled(QueryStatsIcon)`
  font-size: 30px !important;
  color: black;
`;

export const CardHeaderGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CardHeaderTitle = styled.span`
  font-size: 30px;
  font-weight: 600;
`;

export const CardHeaderSubtitle = styled.span`
  font-size: 20px;
  color: #727d86;
`;

export const CardBody = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
`;

export const CardFooter = styled.div``;

export const LocationAndCapacity = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: space-around;
  border: 2px solid #e3e4e8;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
  gap: 20px;
`;

export const LocationAndCapacityItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    border-right: 2px solid #e3e4e8;
  }
`;

export const LocationAndCapacityItemValue = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

export const LocationAndCapacityItemDescription = styled.span`
  font-size: 16px;
  color: #727d86;
`;

export const CardBodyItem = styled.div`
  border: 2px solid #e3e4e8;
  border-radius: 10px;
  display: flex;
  gap: 15px;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const CardItemsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
`;

export const CardBodyItemValue = styled.span``;
