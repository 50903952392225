import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import {InputAdornment, OutlinedInput, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {BaseCard} from '../../common/BaseCard/BaseCard';
import {Button} from '@app/components/common/buttons/Button/Button';
import {BaseTooltip} from '../../common/BaseTooltip/BaseTooltip';

import * as S from './RoofsDataInput.style';
import {CalculatorsInputCard} from '../common/CalculatorsInputCard';
import {BaseCheckbox} from '@app/components/common/BaseCheckbox/BaseCheckbox';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';

interface RoofData {
  azimuthAngle: string;
  tiltAngle: string;
  freeRoofArea: string;
}

interface RoofsDataInputFormProps {
  roofsData: RoofData[];
  setRoofsData: (roofsData: RoofData[]) => void;
  includeRoofSorting: boolean;
  setIncludeRoofSorting: (state: boolean) => void;
}

const RoofsDataInputForm: React.FC<RoofsDataInputFormProps> = ({
  roofsData,
  setRoofsData,
  includeRoofSorting,
  setIncludeRoofSorting,
}) => {
  const {t} = useTranslation();

  const handleRoofRemove = (index: number): void => {
    const list = [...roofsData];
    list.splice(index, 1);
    setRoofsData(list);
  };

  const handleRoofAdd = (): void => {
    setRoofsData([...roofsData, {azimuthAngle: 'none', tiltAngle: 'none', freeRoofArea: 'none'}]);
  };

  const handleDataChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    attribute: keyof RoofData,
  ): void => {
    const list = [...roofsData];
    list[index][attribute] = event.target.value;
    setRoofsData(list);
  };

  return (
    <CalculatorsInputCard
      id="location-form"
      title={t('calculators.inputData.roofsData.title')}
      description={t('calculators.inputData.roofsData.description')}
    >
      <BaseCheckbox.Group style={{marginBottom: '15px'}}>
        <BaseCheckbox
          name="roofSorting"
          value={includeRoofSorting}
          onChange={(event) => setIncludeRoofSorting(event.target.checked)}
        >
          {t('calculators.inputData.roofsData.roofSorting')}
        </BaseCheckbox>
      </BaseCheckbox.Group>

      {roofsData?.map((singleRoof, index) => (
        <RoofDataInputFormElement
          key={index}
          index={index}
          handleRoofRemove={() => handleRoofRemove(index)}
          handleDataChange={handleDataChange}
        />
      ))}

      <Button type="text" icon={<PlusCircleOutlined />} onClick={handleRoofAdd} />
      <BaseTooltip title={<span className="tooltip-text">Add new roof</span>} />
    </CalculatorsInputCard>
  );
};

interface RoofDataInputFormElementProps {
  index: number;
  handleRoofRemove: () => void;
  handleDataChange: (event: React.ChangeEvent<HTMLInputElement>, index: number, attribute: keyof RoofData) => void;
}

const RoofDataInputFormElement: React.FC<RoofDataInputFormElementProps> = ({
  index,
  handleRoofRemove,
  handleDataChange,
}) => {
  const {t} = useTranslation();

  return (
    <S.RoofsInputArea>
      {['azimuthAngle', 'tiltAngle', 'freeRoofArea'].map((attr) => (
        <div className="enterprise-input-field" key={attr}>
          <span>{t(`calculators.inputData.roofsData.${attr}`)}</span>
          <FormControl>
            <OutlinedInput
              label={attr}
              endAdornment={<InputAdornment position="end">{attr === 'freeRoofArea' ? 'm²' : '°'}</InputAdornment>}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleDataChange(event, index, attr as keyof RoofData)
              }
              type="number"
            />
          </FormControl>
        </div>
      ))}
      <Button type="text" icon={<MinusCircleOutlined />} onClick={handleRoofRemove} />
    </S.RoofsInputArea>
  );
};

export default RoofsDataInputForm;
