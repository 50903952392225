export enum eUserRoles {
  Administrator = 'Administrator',
  Manager = 'Manager',
  Supervisor = 'Supervisor',
  Technician = 'Technician',
}

export enum eExcelReportCountryOptions {
  GLOBAL = 'GLOBAL',
  RS = 'RS',
}

export enum eExcelReportLanguageOptions {
  ENGLISH = 'ENGLISH',
  SERBIAN = 'SERBIAN',
}

export enum eExcelReportCalculationTypeOptions {
  HOUSEHOLDS = 'HOUSEHOLDS',
  INDUSTRY = 'INDUSTRY',
}

export enum RoofType {
  Tile = 'tile',
  TrapezoidalSheet = 'trapezoidalSheet',
  HydroIsolation = 'hydro-isolation',
  Other = 'other',
}
