import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
import {BaseRadio} from '@app/components/common/BaseRadio/BaseRadio';
import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import {BaseSelect, Option} from '@app/components/common/selects/BaseSelect/BaseSelect';

import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';

import * as S from './PlaceOffer.styles';
import {createOffer} from '@app/api/offer.api';
import moment from 'moment';

interface FieldData {
  name: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

interface FormValues {
  [key: string]: string | undefined;
}

export const PlaceOffer: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {openProjectId} = useParams();

  const [current, setCurrent] = useState(0);
  const [form] = BaseForm.useForm();
  const [fields, setFields] = useState<FieldData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formLabels: FormValues = {
    openProject: t('linkPro.offer.openProject'),
    developer: t('linkPro.offer.developer'),
    price: t('linkPro.offer.price'),
    paymentTerms: t('linkPro.offer.paymentTerms'),
    projectStartDate: t('linkPro.offer.projectStartDate'),
    preparationWeeks: t('linkPro.offer.preparationWeeks'),
    constructionWeeks: t('linkPro.offer.constructionWeeks'),
    permitWeeks: t('linkPro.offer.permitWeeks'),
    commissioningDate: t('linkPro.offer.commissioningDate'),
    pvModuleManufacturer: t('linkPro.offer.pvModuleManufacturer'),
    pvModuleNominalPower: t('linkPro.offer.pvModuleNominalPower'),
    pvModuleNumber: t('linkPro.offer.pvModuleNumber'),
    pvModuleWarranty: t('linkPro.offer.pvModuleWarranty'),
    inverterManufacturer: t('linkPro.offer.inverterManufacturer'),
    inverterNominalPower: t('linkPro.offer.inverterNominalPower'),
    inverterNumber: t('linkPro.offer.inverterNumber'),
    inverterWarranty: t('linkPro.offer.inverterWarranty'),
    mountingSystemsManufacturer: t('linkPro.offer.mountingSystemsManufacturer'),
    mountingSystemsWarranty: t('linkPro.offer.mountingSystemsWarranty'),
    warrantyOnPerformedWorks: t('linkPro.offer.warrantyOnPerformedWorks'),
    pvPowerPlantDcPower: t('linkPro.offer.pvPowerPlantDcPower'),
    pvPowerPlantAcPower: t('linkPro.offer.pvPowerPlantAcPower'),
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    // setTimeout(() => {
    //   notificationController.success({message: t('common.success')});
    //   setIsLoading(false);
    //   setCurrent(0);
    // }, 1500);

    if (values.projectStartDate) {
      values.projectStartDate = moment(values.projectStartDate).format('YYYY-MM-DD');
    }
    if (values.commissioningDate) {
      values.commissioningDate = moment(values.commissioningDate).format('YYYY-MM-DD');
    }

    values.openProject = openProjectId;
    values.developer = '85e38e33-cffd-44bd-a368-8de28e9744b0';

    createOffer(values)
      .then((response) => {
        notificationController.success({message: t('common.success')});
        setIsLoading(false);
        console.log('Response', response);
      })
      .catch((error) => {
        notificationController.error({message: t('common.error')});
        setIsLoading(false);
      });
  };

  return (
    <>
      <h2>Place offer</h2>
      <S.Form
        name="offerForm"
        form={form}
        onFinish={onFinish}
        fields={fields}
        onFieldsChange={(_, allFields) => {
          const currentFields = allFields.map((item) => ({
            name: Array.isArray(item.name) ? item.name[0] : '',
            value: item.value,
          }));
          setFields(currentFields);
        }}
      >
        <S.FormContent>
          <S.FormGroup>
            <S.FormGroupHeading>General info</S.FormGroupHeading>
            <S.FormItem
              name="price"
              label={formLabels.price}
              rules={[{required: true, message: t('linkPro.offer.priceError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="paymentTerms"
              label={formLabels.paymentTerms}
              rules={[{required: true, message: t('linkPro.offer.paymentTermsError')}]}
            >
              <BaseInput />
            </S.FormItem>

            <S.FormItem
              name="pvPowerPlantDcPower"
              label={formLabels.pvPowerPlantDcPower}
              rules={[{required: true, message: t('linkPro.offer.pvPowerPlantDcPowerError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="pvPowerPlantAcPower"
              label={formLabels.pvPowerPlantAcPower}
              rules={[{required: true, message: t('linkPro.offer.pvPowerPlantAcPowerError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>
          </S.FormGroup>

          <S.Hr />

          <S.FormGroup>
            <S.FormGroupHeading>Timing data</S.FormGroupHeading>

            <S.FormItem
              name="projectStartDate"
              label={formLabels.projectStartDate}
              rules={[{required: true, message: t('linkPro.offer.projectStartDateError')}]}
            >
              <BaseDatePicker format="YYYY-MM-DD" />
            </S.FormItem>

            <S.FormItem
              name="commissioningDate"
              label={formLabels.commissioningDate}
              rules={[{required: true, message: t('linkPro.offer.commissioningDateError')}]}
            >
              <BaseDatePicker format="YYYY-MM-DD" />
            </S.FormItem>

            <S.BreakLine />

            <S.FormItem
              name="preparationWeeks"
              label={formLabels.preparationWeeks}
              rules={[{required: true, message: t('linkPro.offer.preparationWeeksError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="constructionWeeks"
              label={formLabels.constructionWeeks}
              rules={[{required: true, message: t('linkPro.offer.constructionWeeksError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="permitWeeks"
              label={formLabels.permitWeeks}
              rules={[{required: true, message: t('linkPro.offer.permitWeeksError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>
          </S.FormGroup>

          <S.Hr />

          <S.FormGroup>
            <S.FormGroupHeading>PV Module Data</S.FormGroupHeading>

            <S.FormItem
              name="pvModuleManufacturer"
              label={formLabels.pvModuleManufacturer}
              rules={[{required: true, message: t('linkPro.offer.pvModuleManufacturerError')}]}
            >
              <BaseInput />
            </S.FormItem>

            <S.FormItem
              name="pvModuleNominalPower"
              label={formLabels.pvModuleNominalPower}
              rules={[{required: true, message: t('linkPro.offer.pvModuleNominalPowerError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="pvModuleNumber"
              label={formLabels.pvModuleNumber}
              rules={[{required: true, message: t('linkPro.offer.pvModuleNumberError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="pvModuleWarranty"
              label={formLabels.pvModuleWarranty}
              rules={[{required: true, message: t('linkPro.offer.pvModuleWarrantyError')}]}
            >
              <BaseInput />
            </S.FormItem>
          </S.FormGroup>

          <S.Hr />

          <S.FormGroup>
            <S.FormGroupHeading>Inverter Data</S.FormGroupHeading>
            <S.FormItem
              name="inverterManufacturer"
              label={formLabels.inverterManufacturer}
              rules={[{required: true, message: t('linkPro.offer.inverterManufacturerError')}]}
            >
              <BaseInput />
            </S.FormItem>

            <S.FormItem
              name="inverterNominalPower"
              label={formLabels.inverterNominalPower}
              rules={[{required: true, message: t('linkPro.offer.inverterNominalPowerError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="inverterNumber"
              label={formLabels.inverterNumber}
              rules={[{required: true, message: t('linkPro.offer.inverterNumberError')}]}
            >
              <BaseInput type="number" />
            </S.FormItem>

            <S.FormItem
              name="inverterWarranty"
              label={formLabels.inverterWarranty}
              rules={[{required: true, message: t('linkPro.offer.inverterWarrantyError')}]}
            >
              <BaseInput />
            </S.FormItem>
          </S.FormGroup>

          <S.Hr />

          <S.FormGroup>
            <S.FormGroupHeading>Mounting Systems Data</S.FormGroupHeading>
            <S.FormItem
              name="mountingSystemsManufacturer"
              label={formLabels.mountingSystemsManufacturer}
              rules={[{required: true, message: t('linkPro.offer.mountingSystemsManufacturerError')}]}
            >
              <BaseInput />
            </S.FormItem>

            <S.FormItem
              name="mountingSystemsWarranty"
              label={formLabels.mountingSystemsWarranty}
              rules={[{required: true, message: t('linkPro.offer.mountingSystemsWarrantyError')}]}
            >
              <BaseInput />
            </S.FormItem>

            <S.FormItem
              name="warrantyOnPerformedWorks"
              label={formLabels.warrantyOnPerformedWorks}
              rules={[{required: true, message: t('linkPro.offer.warrantyOnPerformedWorksError')}]}
            >
              <BaseInput />
            </S.FormItem>
          </S.FormGroup>
        </S.FormContent>

        <S.Row>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.submit')}
          </BaseButton>
        </S.Row>
      </S.Form>
    </>
  );
};
