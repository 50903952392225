import React from 'react';
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import {withLoading} from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPage';
import IndustryCalculatorPage from '@app/pages/IndustryCalculatorPage';
import HoseholdsCalculatorPage from '@app/pages/HouseholdsCalculatorPage';
import ListOfferPage from '@app/pages/offer/ListOfferPage';
import PlaceOfferPage from '@app/pages/offer/PlaceOfferPage';

const Logout = React.lazy(() => import('./Logout'));

const Dashboard = withLoading(DashboardPage);
const IndustryCalculator = withLoading(IndustryCalculatorPage);
const HoseholdsCalculator = withLoading(HoseholdsCalculatorPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  function Redirect({to}: {to: string}) {
    const navigate = useNavigate();
    React.useEffect(() => {
      navigate(to);
    }, [navigate, to]);

    return null;
  }

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={process.env.REACT_APP_ROUTE_HOME} element={protectedLayout}>
          {/* Dashboard */}
          <Route index element={<Dashboard />} />

          {/* Calculators */}
          <Route
            path={process.env.REACT_APP_ROUTE_HOUSEHOLDS_CALCULATOR + '/:country'}
            element={<HoseholdsCalculator />}
          />
          <Route
            path={process.env.REACT_APP_ROUTE_INDUSTRY_CALCULATOR + '/:country'}
            element={<IndustryCalculator />}
          />

          {/* Offer */}
          <Route path={process.env.REACT_APP_LINK_PRO_OFFERS} element={<ListOfferPage />} />

          <Route path="place-order/:openProjectId" element={<PlaceOfferPage />} />

          {/* Users */}
          {/* <Route path="/users" element={<Redirect to="/users/list" />} />
          <Route path="users">
            <Route path="list" element={<ListUserPage />} />
            <Route path="add" element={<p>Dodaj korisnika </p>} />
            <Route path="view/:userId" element={<ViewUserPage />} />
          </Route> */}
        </Route>

        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>

        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
