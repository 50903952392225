import {httpApi} from './http.api';
import {IndustryRequestModel} from '@app/domain/IndustryRequestModel';
import {RSHouseholdResponseModel} from '@app/domain/RSHouseholdResponseModel';
import {
  eExcelReportCalculationTypeOptions,
  eExcelReportCountryOptions,
  eExcelReportLanguageOptions,
} from '@app/interfaces/enums';

export const calculateIndustryDataRS = (industryData: IndustryRequestModel): Promise<any> =>
  httpApi.post<any>(process.env.REACT_APP_API_INDUSTRY_RS!, industryData).then(({data}) => data);

export const calculateIndustryDataGlobal = (industryData: IndustryRequestModel): Promise<any> =>
  httpApi.post<any>(process.env.REACT_APP_API_INDUSTRY_GLOBAL!, industryData).then(({data}) => data);

export const generateExcelReport = (
  calculationData: any,
  country: eExcelReportCountryOptions,
  language: eExcelReportLanguageOptions,
): Promise<URL> =>
  httpApi
    .post(process.env.REACT_APP_API_GENERATE_EXCEL_INDUSTRY!, {
      metaData: {
        country: country,
        language: language,
        calculationType: eExcelReportCalculationTypeOptions.INDUSTRY,
      },
      calculationData: calculationData,
    })
    .then(({data}) => data);

// interface GenerateExcelReportRequest {
//   metaData: {
//     country: eExcelReportCountryOptions;
//     language: eExcelReportLanguageOptions;
//     calculationType: eExcelReportCalculationTypeOptions;
//   };
//   calculationData: RSHouseholdResponseModel;
// }

// export const generateExcelReport = (generateExcelReportRequest: GenerateExcelReportRequest): Promise<URL> =>
//   httpApi.post(process.env.REACT_APP_API_GENERATE_EXCEL_HOUSEHOLDS!, generateExcelReportRequest).then(({ data }) => data);
