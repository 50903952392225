import {httpLinkProApi} from './httpLinkPro.api';
import {v4 as uuidv4} from 'uuid';
import {OpenProjectModel} from './openProject.api';

interface Developer {
  // Definišite odgovarajuća polja za Developer
  name: string;
}

export class OfferModel {
  offerId: string;
  openProject: OpenProjectModel;
  developer: Developer;
  price: number;
  paymentTerms: string;
  projectStartDate: Date;
  preparationWeeks: number;
  constructionWeeks: number;
  permitWeeks: number;
  commissioningDate: Date;
  pvModuleManufacturer: string;
  pvModuleNominalPower: number;
  pvModuleNumber: number;
  pvModuleWarranty: string;
  inverterManufacturer: string;
  inverterNominalPower: number;
  inverterNumber: number;
  inverterWarranty: string;
  mountingSystemsManufacturer: string;
  mountingSystemsWarranty: string;
  warrantyOnPerformedWorks: string;
  pvPowerPlantDcPower: number;
  pvPowerPlantAcPower: number;

  constructor(
    openProject: OpenProjectModel,
    developer: Developer,
    price: number,
    paymentTerms: string,
    projectStartDate: Date,
    preparationWeeks: number,
    constructionWeeks: number,
    permitWeeks: number,
    commissioningDate: Date,
    pvModuleManufacturer: string,
    pvModuleNominalPower: number,
    pvModuleNumber: number,
    pvModuleWarranty: string,
    inverterManufacturer: string,
    inverterNominalPower: number,
    inverterNumber: number,
    inverterWarranty: string,
    mountingSystemsManufacturer: string,
    mountingSystemsWarranty: string,
    warrantyOnPerformedWorks: string,
    pvPowerPlantDcPower: number,
    pvPowerPlantAcPower: number,
  ) {
    this.offerId = uuidv4(); // Generiše UUID
    this.openProject = openProject;
    this.developer = developer;
    this.price = price;
    this.paymentTerms = paymentTerms;
    this.projectStartDate = projectStartDate;
    this.preparationWeeks = preparationWeeks;
    this.constructionWeeks = constructionWeeks;
    this.permitWeeks = permitWeeks;
    this.commissioningDate = commissioningDate;
    this.pvModuleManufacturer = pvModuleManufacturer;
    this.pvModuleNominalPower = pvModuleNominalPower;
    this.pvModuleNumber = pvModuleNumber;
    this.pvModuleWarranty = pvModuleWarranty;
    this.inverterManufacturer = inverterManufacturer;
    this.inverterNominalPower = inverterNominalPower;
    this.inverterNumber = inverterNumber;
    this.inverterWarranty = inverterWarranty;
    this.mountingSystemsManufacturer = mountingSystemsManufacturer;
    this.mountingSystemsWarranty = mountingSystemsWarranty;
    this.warrantyOnPerformedWorks = warrantyOnPerformedWorks;
    this.pvPowerPlantDcPower = pvPowerPlantDcPower;
    this.pvPowerPlantAcPower = pvPowerPlantAcPower;
  }
}
export const getAllOffers = (): Promise<OfferModel[]> =>
  httpLinkProApi.get<any>(process.env.REACT_APP_LINK_PRO_API_OFFERS!).then(({data}) => data);

export const getSingleOffer = (offerId: string): Promise<OfferModel> =>
  httpLinkProApi.get<any>(`${process.env.REACT_APP_LINK_PRO_API_OFFERS!}/${offerId}`).then(({data}) => data);

export const createOffer = (offer: OfferModel): Promise<OfferModel> =>
  httpLinkProApi.post<any>(process.env.REACT_APP_LINK_PRO_API_OFFERS! + '/', offer).then(({data}) => data);
