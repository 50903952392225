import * as React from 'react';
import {BaseCard} from '../common/BaseCard/BaseCard';
import {useTranslation} from 'react-i18next';
import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {Input} from 'antd';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import NumericInput from '../common/inputs/NumericInput/NumericInput';

interface IPanelsInformationInputProps {
  edit?: boolean;
}

export const PanelsInformationInput: React.FC<IPanelsInformationInputProps> = () => {
  const {t} = useTranslation();

  const [modulePower, setModulePower] = React.useState<string>('');
  const [moduleLength, setModuleLength] = React.useState<string>('');
  const [moduleWidth, setModuleWidth] = React.useState<string>('');

  return (
    <CalculatorsInputCard
      id="panelsInformation"
      title={t('calculators.inputData.panelsInformation.title')}
      description={t('calculators.inputData.panelsInformation.description')}
    >
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '15px'}}>
        <div>
          <BaseButtonsForm.Item
            name="modulePower"
            label={t('calculators.inputData.panelsInformation.nominalPower')}
            rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
          >
            <NumericInput min={0} suffix="Wp" value={modulePower} onChange={setModulePower} />
          </BaseButtonsForm.Item>
        </div>

        <div>
          <BaseButtonsForm.Item
            name="moduleLength"
            label={t('calculators.inputData.panelsInformation.length')}
            rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
          >
            <NumericInput min={0} suffix="mm" value={moduleLength} onChange={setModuleLength} />
          </BaseButtonsForm.Item>
        </div>

        <div>
          <BaseButtonsForm.Item
            name="moduleWidth"
            label={t('calculators.inputData.panelsInformation.width')}
            rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
          >
            <NumericInput min={0} suffix="mm" value={moduleWidth} onChange={setModuleWidth} />
          </BaseButtonsForm.Item>
        </div>
      </div>
    </CalculatorsInputCard>
  );
};
