import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';
import {BaseInput} from '@app/components/common/inputs/BaseInput/BaseInput';
// import {
//   CreateMachineRequest,
//   UpdateMachineRequest,
//   createMachine,
//   getSingleMachine,
//   updateMachine,
// } from '@app/api/machine.api';
import {Form, Select} from 'antd';
import moment from 'moment';
import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';

import {GeolocationCoordinates} from '../GeolocationCoordinates';
import RoofsDataInput from '../RoofsDataInput/RoofsDataInput';
import {PanelsInformationInput} from '../PanelsInformationInput';
import {ElectricityConsumptionInput} from '../ElectricityConsumptionInput';
import {RoofTypeInput} from '../RoofTypeInput';
import {InvestmentCostInput} from '../InvestmentCostInput';
import WorkingHoursInput from '../WorkingHoursInput';
import {RSHouseholdResponseModel} from '@app/domain/RSHouseholdResponseModel';
import CollectiveAnnualVacationInput from '../CollectiveAnnualVacationInput';
import {IndustryMonthlyElectricityInput} from '../IndustryMonthlyElectricityInput';
import {IndustryElectricityDataInput} from '../IndustryElectricityDataInput';
import {IndustryRequestModel} from '@app/domain/IndustryRequestModel';
import {openLinkInNewTab, splitCoordinates, validateCoordinates} from '@app/utils/utils';
import {RoofType, eExcelReportCountryOptions, eExcelReportLanguageOptions} from '@app/interfaces/enums';
import {
  calculateIndustryDataGlobal,
  calculateIndustryDataRS,
  generateExcelReport,
} from '@app/api/industryCalculator.api';

interface IIndustryCalculatorFormProps {
  edit?: boolean;
}

export const IndustryCalculatorForm: React.FC<IIndustryCalculatorFormProps> = ({edit}) => {
  const {t} = useTranslation();
  const {country} = useParams();

  const [isLoading, setLoading] = useState(false);
  const [calculationCompleted, setCalculationCompleted] = useState(false);
  const [calculationData, setCalculationData] = useState<RSHouseholdResponseModel | null>(null);

  const [annualVacationData, setAnnualVacationData] = useState([{month: 'none', days: 'none'}]);

  const [roofsData, setRoofsData] = useState([{azimuthAngle: 'none', tiltAngle: 'none', freeRoofArea: 'none'}]);
  const [includeRoofSorting, setIncludeRoofSorting] = useState<boolean>(false);
  const [workingHours, setWorkingHours] = useState({
    monday: 'null',
    tuesday: 'null',
    wednesday: 'null',
    thursday: 'null',
    friday: 'null',
    saturday: 'null',
    sunday: 'null',
  });

  const [selectedExcelLanguage, setSelectedExcelLanguage] = useState<eExcelReportLanguageOptions>(
    eExcelReportLanguageOptions.ENGLISH,
  );

  const initialValues = {
    // geolocationCoordinates: '44.787197, 20.457273',
    // roofsData: [{azimuthAngle: '30', tiltAngle: '20', freeRoofArea: '48'}],
    // annualConsumption: '4000',
    // modulePower: '50',
    // moduleLength: '30',
    // moduleWidth: '200',
    // investmentCost: '60000',
    // roofType: 'hydro-isolation',
    // higherTariffShare: '80',
  };

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    if (!validateCoordinates(values.latitude, values.longitude)) {
      notificationController.error({message: t('calculators.notifications.errors.geolocationError')});
      return;
    }

    const data: IndustryRequestModel = {
      // Geolocation Coordinates
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),

      // Roofs Data
      roofs: roofsData.map((roof) => ({
        azimuthAngle: parseInt(roof.azimuthAngle, 10),
        inclinationAngle: parseInt(roof.tiltAngle, 10),
        freeRoofArea: parseInt(roof.freeRoofArea, 10),
      })),
      includeRoofSorting: includeRoofSorting,

      // Monthly Consumption Data
      monthlyConsumption: {
        january: parseInt(values.januaryElectricityConsumption),
        february: parseInt(values.februaryElectricityConsumption),
        march: parseInt(values.marchElectricityConsumption),
        april: parseInt(values.aprilElectricityConsumption),
        may: parseInt(values.mayElectricityConsumption),
        june: parseInt(values.juneElectricityConsumption),
        july: parseInt(values.julyElectricityConsumption),
        august: parseInt(values.augustElectricityConsumption),
        september: parseInt(values.septemberElectricityConsumption),
        october: parseInt(values.octoberElectricityConsumption),
        november: parseInt(values.novemberElectricityConsumption),
        december: parseInt(values.decemberElectricityConsumption),
      },
      maximumAuthorizedPower: parseInt(values.maximumAuthorizedPower),

      // Panels information
      modulePower: parseInt(values.modulePower),
      moduleLength: parseInt(values.moduleLength),
      moduleWidth: parseInt(values.moduleWidth),

      // Annual Vacation Data
      annualVacationData: annualVacationData.map((vacation) => ({
        month: vacation.month,
        days: parseInt(vacation.days),
      })),

      // Working Hours
      // TODO
      workingHours: workingHours,
      // workingHours: {
      //   friday: '0800-1600',
      //   monday: '0800-1600',
      //   saturday: '0800-1600',
      //   sunday: 'null',
      //   thursday: '0800-1600',
      //   tuesday: '0800-1600',
      //   wednesday: '0800-1600'
      // },

      // Industry Electricity Data
      basePriceOfElectricity: parseInt(values.basePriceOfElectricity),
      gridFee: parseInt(values.additionalGridFeesAndTaxes) / 100,
      priceOfExportedElectricity: parseInt(values.sellingPriceOfElectricity),

      // Roof Type
      roofType: RoofType[values.roofType as keyof typeof RoofType],

      // Investment Cost
      investmentCost: parseInt(values.investmentCost),
    };

    console.log(data);

    switch (country) {
      case 'rs':
        calculateIndustryDataRS(data)
          .then((res) => {
            setLoading(false);
            setCalculationCompleted(true);
            setCalculationData(res);

            notificationController.success({message: t('calculators.notifications.success.calculationSuccess')});
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            notificationController.error({message: t('calculators.notifications.errors.calculatingError')});
          });
        break;

      case 'global':
        calculateIndustryDataGlobal(data)
          .then((res) => {
            setLoading(false);
            setCalculationCompleted(true);
            setCalculationData(res);

            notificationController.success({message: t('calculators.notifications.success.calculationSuccess')});
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            notificationController.error({message: t('calculators.notifications.errors.calculatingError')});
          });
        break;
    }

    // calculateHouseholdDataForRS(data)
    //   .then((res) => {
    //     setLoading(false);
    //     setCalculationCompleted(true);
    //     setCalculationData(res);

    //     notificationController.success({message: t('calculators.notifications.success.calculationSuccess')});
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //     notificationController.error({message: t('calculators.notifications.errors.calculatingError')});
    //   });
  };

  const getExcelReport = () => {
    setLoading(true);
    if (!calculationData) {
      setLoading(false);
      notificationController.error({message: t('calculators.notifications.errors.startingExcelGenerationError')});
      return;
    }

    let selectedCountry = eExcelReportCountryOptions.GLOBAL;

    switch (country) {
      case 'rs':
        selectedCountry = eExcelReportCountryOptions.RS;
        break;
      case 'global':
        selectedCountry = eExcelReportCountryOptions.GLOBAL;
        break;
    }
    generateExcelReport(calculationData, selectedCountry, eExcelReportLanguageOptions.ENGLISH)
      .then((res) => {
        openLinkInNewTab(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notificationController.error({message: t('calculators.notifications.errors.startingExcelGenerationError')});
      });
  };

  return (
    <>
      <BaseButtonsForm
        {...formItemLayout}
        isFieldsChanged={true}
        // onFieldsChange={() => setFieldsChanged(true)}
        // initialValues={initialValues}
        name="validateForm"
        footer={
          <BaseButtonsForm.Item>
            <BaseButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
        onFinish={onFinish}
      >
        <BaseRow gutter={[30, 30]}>
          <BaseCol xs={24} sm={24} xl={14} style={{display: 'flex'}}>
            <BaseRow gutter={[30, 30]}>
              {/* Geolocation Coordinates */}
              <BaseCol span={24}>
                <GeolocationCoordinates />
              </BaseCol>

              {/* Roofs Data  */}
              <BaseCol span={24}>
                <RoofsDataInput
                  roofsData={roofsData}
                  setRoofsData={setRoofsData}
                  includeRoofSorting={includeRoofSorting}
                  setIncludeRoofSorting={setIncludeRoofSorting}
                />
              </BaseCol>

              {/* Monthly Consumption Data  */}
              <BaseCol span={24}>
                <IndustryMonthlyElectricityInput />
              </BaseCol>

              {/* Panels information  */}
              <BaseCol span={24}>
                <PanelsInformationInput />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol xs={24} sm={24} xl={10} style={{display: 'flex'}}>
            <BaseRow gutter={[30, 30]}>
              {/* Annual Vacation Data */}
              <BaseCol span={24}>
                <CollectiveAnnualVacationInput
                  annualVacationData={annualVacationData}
                  setAnnualVacationData={setAnnualVacationData}
                />
              </BaseCol>

              {/* Working Hours*/}
              <BaseCol span={24}>
                <WorkingHoursInput setWorkingHours={setWorkingHours} workingHours={workingHours} />
              </BaseCol>

              {/* Industry Electricity Data  */}
              <BaseCol span={24}>
                <IndustryElectricityDataInput />
              </BaseCol>

              {/* Roof Type  */}
              <BaseCol span={24}>
                <RoofTypeInput />
              </BaseCol>

              {/* Investment Cost  */}
              <BaseCol span={24}>
                <InvestmentCostInput />
              </BaseCol>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>

      {calculationCompleted && (
        <Col span={24} className="action-buttons">
          <div
            style={{
              float: 'right',
              padding: '15px',
              background: '#ffffff',
              fontSize: '16px',
              borderRadius: '7px',
            }}
          >
            <BaseRow gutter={[30, 30]}>
              {country === 'rs' && (
                <BaseCol xs={12} sm={12} xl={12}>
                  <div>
                    <label>{t('excel.choseLanguage')}</label>
                    <Select
                      style={{width: '100%'}}
                      options={[
                        {value: eExcelReportLanguageOptions.SERBIAN, label: t('languages.serbian')},
                        {value: eExcelReportLanguageOptions.ENGLISH, label: t('languages.english')},
                      ]}
                      onChange={(value) => setSelectedExcelLanguage(value as eExcelReportLanguageOptions)}
                    />
                  </div>
                </BaseCol>
              )}
              <BaseCol xs={12} sm={12} xl={12}>
                <BaseButton severity="warning" onClick={() => getExcelReport()} loading={isLoading}>
                  {t('excel.exportButton')}
                </BaseButton>
              </BaseCol>
            </BaseRow>
          </div>

          {/* <BaseButton severity="info" onClick={() => setIsResultsModalVisible(true)}>
                  Pogledaj rezultate
                </BaseButton> */}
        </Col>
      )}

      <Col span={24}>
        {/* <Button type="primary" onClick={() => setIsResultsModalVisible(true)}>
                {t('modals.fullscreen')}
              </Button> */}
        <div className="households-calculator-buttons">
          {/* <Button type="ghost" onClick={clearForm} loading={isLoading}>
                  {t('solarCalculatorsPro.buttons.clear')}
                </Button> */}

          {/* <BaseButton type="primary" onClick={handleFormSubmit} loading={isLoading}>
                  {t('solarCalculatorsPro.buttons.submit')}
                </BaseButton> */}
        </div>
      </Col>

      {/* {calculationData && (
        <HouseholdsCalculatorResultsModal
          visible={isResultsModalVisible}
          onClose={() => setIsResultsModalVisible(false)}
          data={calculationData}
        />
      )} */}
    </>
  );
};
