import * as React from 'react';
import {TFunction} from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {Button} from '@app/components/common/buttons/Button/Button';
import {PlusCircleOutlined, MinusCircleOutlined} from '@ant-design/icons';
import {InputAdornment, OutlinedInput} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {getDaysInMonth, getMonths} from '@app/utils/utils';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';

import './CollectiveAnnualVacation.css';

interface AnnualVacationData {
  month: string;
  days: string;
}

interface CollectiveAnnualVacationInputFormProps {
  annualVacationData: AnnualVacationData[];
  setAnnualVacationData: React.Dispatch<React.SetStateAction<AnnualVacationData[]>>;
}

const CollectiveAnnualVacationInputForm: React.FC<CollectiveAnnualVacationInputFormProps> = (props) => {
  const {t} = useTranslation();

  const handleMonthRemove = (index: number) => {
    const list = [...props.annualVacationData];
    list.splice(index, 1);
    props.setAnnualVacationData(list);
  };

  const handleMonthAdd = () => {
    props.setAnnualVacationData([...props.annualVacationData, {month: 'none', days: 'none'}]);
  };

  const handleMonthSelect = (month: string, index: number) => {
    const list = [...props.annualVacationData];
    list[index].month = month;
    props.setAnnualVacationData(list);
  };

  const handleDayInput = (days: string, index: number) => {
    const list = [...props.annualVacationData];
    list[index].days = days;
    props.setAnnualVacationData(list);
  };

  const getAvailableMonths = (selectedMonth: string) => {
    return getMonths().filter(
      (month) => !props.annualVacationData.some((x) => month === x.month && month !== selectedMonth),
    );
  };

  return (
    <CalculatorsInputCard
      id="collectiveAnnualVacation"
      title={t('calculators.inputData.collectiveAnnualVacation.title')}
      description={t('calculators.inputData.collectiveAnnualVacation.description')}
    >
      {props.annualVacationData.map((singleMonth, index) => (
        <CollectiveAnnualVacationInputElement
          key={index}
          index={index}
          getAvailableMonths={getAvailableMonths}
          handleMonthSelect={handleMonthSelect}
          handleDayInput={handleDayInput}
        />
      ))}

      <div className="collective-annual-vacation-buttons">
        <Button type={'text'} icon={<PlusCircleOutlined />} onClick={handleMonthAdd} />
        <Button
          type={'text'}
          icon={<MinusCircleOutlined />}
          onClick={() => handleMonthRemove(props.annualVacationData.length - 1)}
        />
      </div>
    </CalculatorsInputCard>
  );
};

interface CollectiveAnnualVacationInputElementProps {
  index: number;
  getAvailableMonths: (selectedMonth: string) => string[];
  handleMonthSelect: (month: string, index: number) => void;
  handleDayInput: (days: string, index: number) => void;
}

const CollectiveAnnualVacationInputElement: React.FC<CollectiveAnnualVacationInputElementProps> = (props) => {
  const {t} = useTranslation();

  const [selectedMonth, setSelectedMonth] = React.useState<string>('none');
  const [numberOfDays, setNumberOfDays] = React.useState<string>('none');
  const [maxDays, setMaxDays] = React.useState<number>(0);

  const handleMonthChange = (event: SelectChangeEvent<unknown>) => {
    const month = event.target.value as string;
    setSelectedMonth(month);
    setMaxDays(getDaysInMonth(month));
    props.handleMonthSelect(month, props.index);
  };

  const handleDaysChange = (event: React.ChangeEvent<{value: unknown}>) => {
    let days = event.target.value as string;
    if (parseInt(days) > maxDays) {
      days = maxDays.toString();
    }
    if (parseInt(days) < 0) {
      days = '0';
    }

    props.handleDayInput(days, props.index);
    setNumberOfDays(days);
  };

  return (
    <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px', marginBottom: '10px'}}>
      <FormControl>
        <InputLabel htmlFor="collective-annual-vacation-month-label">{t('months.month')}</InputLabel>
        <Select
          id="collective-annual-vacation-month-label"
          onChange={handleMonthChange}
          autoWidth
          label={t('months.month')}
        >
          <MenuItem value="none">{t('calculators.inputData.collectiveAnnualVacation.none')}</MenuItem>
          {props.getAvailableMonths(selectedMonth).map((day, index) => (
            <MenuItem key={index} value={day}>
              {t('months.' + day.toString())}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel htmlFor="collective-annual-vacation-days-label">
          {t('calculators.inputData.collectiveAnnualVacation.numberOfDays')}
        </InputLabel>
        <OutlinedInput
          id="collective-annual-vacation-days-label"
          disabled={selectedMonth === '' || selectedMonth === 'none'}
          value={numberOfDays}
          onChange={handleDaysChange}
          label={t('calculators.inputData.collectiveAnnualVacation.numberOfDays')}
          type="number"
        />
      </FormControl>
    </div>
  );
};

export default CollectiveAnnualVacationInputForm;
