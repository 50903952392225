import * as React from 'react';
import {BaseCard} from '../common/BaseCard/BaseCard';
import {useTranslation} from 'react-i18next';
import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {Select} from 'antd';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';

interface IRoofTypeInputProps {
  edit?: boolean;
}

export const RoofTypeInput: React.FC<IRoofTypeInputProps> = () => {
  const {t} = useTranslation();

  return (
    <CalculatorsInputCard
      id="roofType"
      title={t('calculators.inputData.roofType.title')}
      description={t('calculators.inputData.roofType.description')}
    >
      <BaseButtonsForm.Item
        name="roofType"
        label={t('calculators.inputData.roofType.title')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <Select
          style={{width: '100%'}}
          options={[
            {value: 'Tile', label: t('calculators.inputData.roofType.tile')},
            {value: 'TrapezoidalSheet', label: t('calculators.inputData.roofType.trapezoidalSheet')},
            {value: 'HydroIsolation', label: t('calculators.inputData.roofType.hydro-isolation')},
            {value: 'Other', label: t('calculators.inputData.roofType.other')},
          ]}
        />
      </BaseButtonsForm.Item>
    </CalculatorsInputCard>
  );
};
