import {DecodedTokenModel, TokenModel} from '@app/domain/TokenModel';
import {UserModel} from '@app/domain/UserModel';
import {eUserRoles} from '@app/interfaces/enums';
import {jwtDecode} from 'jwt-decode';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

const testUser = {
  id: 1,
  firstName: 'TEST USER',
  lastName: 'TEST USER',
  imgUrl: avatarImg,
  userName: '@TESTUSER',
  email: {
    name: 'marko.markovic@smartmaintenance',
    verified: false,
  },
  phone: {
    number: '+381641112222',
    verified: true,
  },
  sex: 'male',
  birthday: '01/26/1985',
  lang: 'en',
  country: 'RS',
  city: 'Beograd',
  address1: 'Beogradska 1',
  zipcode: 11000,
  website: 'smartmaintenance.rs',
  socials: {
    twitter: '@smartmaintenance.rs',
    facebook: 'https://facebook.com/groups/1076577369582221',
    linkedin: 'https://linkedin.com/company/altence',
  },
};

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string => {
  return localStorage.getItem('accessToken') || 'bearerToken';
};

export const decodeToken = (token: string): TokenModel => {
  const decodedToken = jwtDecode<DecodedTokenModel>(token);

  console.log(decodedToken);

  return {
    userId: decodedToken['Id'],
    userRole:
      eUserRoles[
        decodedToken[
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        ].toLowerCase() as keyof typeof eUserRoles
      ],
  };
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : testUser;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
