import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {BaseCard} from '@app/components/common/BaseCard/BaseCard';

interface ICalculatorsInputCardProps {
  id: string;
  title: string;
  description: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const CalculatorsInputCard: React.FC<ICalculatorsInputCardProps> = ({id, title, description, children}) => {
  const {t} = useTranslation();

  return (
    <BaseCard id={id} title={title} padding={[0, 20]}>
      <hr />
      <p>
        <i>{description}</i>
      </p>
      {children}
    </BaseCard>
  );
};
