import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import NumericInput from '../common/inputs/NumericInput/NumericInput';

interface IElectricityConsumptionInputProps {
  edit?: boolean;
}

export const ElectricityConsumptionInput: React.FC<IElectricityConsumptionInputProps> = () => {
  const {t} = useTranslation();

  const [higherTariffShare, setHigherTariffShare] = React.useState<string>('');
  const [annualConsumption, setAnnualConsumption] = React.useState<string>('');

  React.useEffect(() => {
    console.log(annualConsumption);
  }, []);

  return (
    <CalculatorsInputCard
      id="electricityConsumption"
      title={t('calculators.inputData.electricityConsumption.title')}
      description={t('calculators.inputData.electricityConsumption.description')}
    >
      <BaseButtonsForm.Item
        name="annualConsumption"
        label={t('calculators.inputData.electricityConsumption.annualConsumption')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <NumericInput min={0} suffix="kWh" value={annualConsumption} onChange={setAnnualConsumption} />
      </BaseButtonsForm.Item>

      <BaseButtonsForm.Item
        name="higherTariffShare"
        label={t('calculators.inputData.electricityConsumption.higherTariffShare')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <NumericInput min={0} max={100} suffix="%" value={higherTariffShare} onChange={setHigherTariffShare} />
      </BaseButtonsForm.Item>
    </CalculatorsInputCard>
  );
};
