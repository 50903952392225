import React, {useState} from 'react';
import {FileExcelOutlined, PaperClipOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space} from 'antd';
import {OfferModel} from '@app/api/offer.api';
import {OpenProjectDocumentModel, OpenProjectModel} from '@app/api/openProject.api';
import {BaseSkeleton} from '@app/components/common/BaseSkeleton/BaseSkeleton';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {useNavigate} from 'react-router-dom';
import * as S from './OpenProjetDrawer.styles';

interface OfferDetailsDrawer {
  open: boolean;
  onClose: () => void;
  openProject: OpenProjectModel | null;
}

const OpenProjetDrawer: React.FC<OfferDetailsDrawer> = ({open, onClose, openProject}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();

  const showLoading = () => {
    // setOpen(true);
    setLoading(true);

    // Simple loading mock. You should add cleanup logic in real world.
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <S.ProjectDrawer
        closable
        destroyOnClose
        title="Offer details"
        placement="right"
        open={open}
        // loading={loading}
        onClose={onClose}
        width={600}
      >
        {openProject ? (
          <>
            <S.Heading>Open project</S.Heading>

            <S.GroupValues>
              <S.Value>
                Plant Capacity: <b>{openProject.plantCapacity}</b>
              </S.Value>
              <S.Value>
                Location: <b>{openProject.location}</b>
              </S.Value>
              <S.Value>
                Roof Structure: <b>{openProject.roofStructure}</b>
              </S.Value>
              <S.Value>
                Investment Timeline: <b>{openProject.investmentTimeline}</b>
              </S.Value>
            </S.GroupValues>

            <S.AttachmentContainer>
              <S.AttachmentHeader>
                <S.AttachmentIcon />
                <S.AttachmentHeading>Attachment ({openProject.documents.length})</S.AttachmentHeading>
              </S.AttachmentHeader>

              <S.AttachmentBody>
                {openProject.documents.map((document) => (
                  <OpenProjectDocumentation key={document.documentId} document={document} />
                ))}
              </S.AttachmentBody>
            </S.AttachmentContainer>
            <S.ButtonsContainer>
              <BaseButton onClick={() => navigate(`/place-order/${openProject.projectId}`)}>Place Offer</BaseButton>
            </S.ButtonsContainer>
          </>
        ) : (
          <BaseSkeleton active />
        )}
      </S.ProjectDrawer>
    </>
  );
};

export default OpenProjetDrawer;

interface OpenProjectDocumentationProps {
  document: OpenProjectDocumentModel;
}

const OpenProjectDocumentation: React.FC<OpenProjectDocumentationProps> = ({document}) => {
  const handleDocumentClick = () => {
    window.open(document.documentPath, '_blank');
  };

  return (
    <S.AttachmentItem onClick={handleDocumentClick}>
      <S.DocumentIcon />
      <div>
        <span>{document.documentType}</span>
        <div>
          <span>2,7 MB</span>
          <span> ● </span>
          <span>Download</span>
        </div>
      </div>
    </S.AttachmentItem>
  );
};
