import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEN from './locales/en/translation.json';
// import translationDE from './locales/de/translation.json';
import translationRS from './locales/rs/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  // de: {
  //   translation: translationDE,
  // },
  rs: {
    translation: translationRS,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'rs',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
