import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseTable} from '../common/BaseTable/BaseTable';
import {BasicTableRow, Pagination, Tag} from '@app/api/table.api';
import {useMounted} from '@app/hooks/useMounted';
import {ColumnsType} from 'antd/lib/table';
import {useNavigate} from 'react-router-dom';
import {getAllOpenProjects, OpenProjectModel} from '@app/api/openProject.api';
import OfferDetailsDrawer from './components/OpenProjetDrawer';
import {CardOpenProject} from './components/CardOpenProject';

export const ListOffer: React.FC = () => {
  const {t} = useTranslation();
  const {isMounted} = useMounted();
  const navigate = useNavigate();

  const [openProjects, setOpenProjects] = useState<OpenProjectModel[]>([]);
  const [selectedProject, setSelectedProject] = useState<OpenProjectModel | null>(null);

  const fetchOffers = useCallback(() => {
    getAllOpenProjects().then((res) => {
      if (isMounted.current) {
        setOpenProjects(res);
      }
    });
  }, [isMounted]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const openProjectDetails = (openProject: OpenProjectModel) => {
    setSelectedProject(openProject);
    showDrawer();
  };

  return (
    <>
      Open Projects
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '15px',
        }}
      >
        {openProjects.map((openProject, key) => (
          <CardOpenProject key={key} openProjet={openProject} onClick={openProjectDetails} />
        ))}
      </div>
      <OfferDetailsDrawer open={openDrawer} onClose={onClose} openProject={selectedProject} />
    </>
  );
};
