import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {ListOffer} from '@app/components/offer/ListOffer';

const ListOfferPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <PageTitle>{t('linkPro.offer.pageTitle')}</PageTitle>
      <PageLayout title={t('linkPro.offer.pageTitle')} backButtonLink={process.env.REACT_APP_ROUTE_HOME}>
        <ListOffer />
      </PageLayout>
    </>
  );
};

export default ListOfferPage;
