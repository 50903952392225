import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import NumericInput from '../common/inputs/NumericInput/NumericInput';

export const InvestmentCostInput: React.FC = () => {
  const {t} = useTranslation();

  const [investmentCost, setInvestmentCost] = React.useState<string>('');

  return (
    <CalculatorsInputCard
      id="investmentCost"
      title={t('calculators.inputData.investmentCost.title')}
      description={t('calculators.inputData.investmentCost.description')}
    >
      <BaseButtonsForm.Item
        name="investmentCost"
        label={t('calculators.inputData.investmentCost.label')}
        rules={[{required: true, message: t('machine.form.validationLabels.serialNumber')}]}
      >
        <NumericInput min={0} suffix="EUR / kWp" value={investmentCost} onChange={setInvestmentCost} />
      </BaseButtonsForm.Item>
    </CalculatorsInputCard>
  );
};
