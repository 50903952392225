import styled from 'styled-components';
import {media} from '@app/styles/themes/constants';
import {BaseLayout} from '@app/components/common/BaseLayout/BaseLayout';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BackButton = styled(BaseButton)`
  padding: 0;
`;

export const VerticalHr = styled.div`
  width: 1px;
  height: 24px;
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
  background-color: #bfc0c2;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const PageOptions = styled.div`
  display: flex;
  gap: 20px;
`;

export const PageContent = styled.div``;

export const Btn = styled(BaseButton)`
  display: block;
  padding: 10px;
`;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0 0 50px 0;
  border-left: 0;
  opacity: 0.6;
`;
