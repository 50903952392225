import React, {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import {WithChildrenProps} from '@app/types/generalTypes';
import {notificationController} from '@app/controllers/notificationController';
import {checkTokenExpiration} from '@app/store/slices/authSlice';

const RequireAuth: React.FC<WithChildrenProps> = ({children}) => {
  const [token, setToken] = useState('');
  const reduxToken = useAppSelector((state) => state.auth.token);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(checkTokenExpiration())
      .unwrap()
      .then(() => {
        console.log('Token is validsddddddddddddd');
        setToken(reduxToken!);
        setIsLoading(false);
      })
      .catch((err) => {
        //otkomentarisati kad se sredi logika za proveru tokena WEBNET
        notificationController.error({message: err.message});
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return null;
  }

  // return <>{children}</>;
  return token != '' ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
