import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';
import React, {useEffect, useState} from 'react';
import {Col, Row, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {notificationController} from '@app/controllers/notificationController';

import {BaseRow} from '@app/components/common/BaseRow/BaseRow';
import {BaseCol} from '@app/components/common/BaseCol/BaseCol';

import {GeolocationCoordinates} from '../GeolocationCoordinates';
import RoofsDataInput from '../RoofsDataInput/RoofsDataInput';
import {PanelsInformationInput} from '../PanelsInformationInput';
import {ElectricityConsumptionInput} from '../ElectricityConsumptionInput';
import {RoofTypeInput} from '../RoofTypeInput';
import {InvestmentCostInput} from '../InvestmentCostInput';
import {RSHouseholdRequest} from '@app/domain/RSHouseholdRequestModel';
import {
  calculateHouseholdDataForRS,
  calculateHouseholdDataGlobal,
  generateExcelReportGlobal,
  generateExcelReportRS,
} from '@app/api/householdCalculator.api';
import {
  RoofType,
  eExcelReportCalculationTypeOptions,
  eExcelReportCountryOptions,
  eExcelReportLanguageOptions,
} from '@app/interfaces/enums';
import {openLinkInNewTab, splitCoordinates, validateCoordinates} from '@app/utils/utils';
import {RSHouseholdResponseModel} from '@app/domain/RSHouseholdResponseModel';
import {IndustryElectricityDataInput} from '../IndustryElectricityDataInput';
import {IndustryMonthlyElectricityInput} from '../IndustryMonthlyElectricityInput';
import {IndustryRequestModel} from '@app/domain/IndustryRequestModel';
import {HouseholdGlobalRequestModel} from '@app/domain/HouseholdRequestModel';
import {CalculatorsInputCard} from '../common/CalculatorsInputCard';
import {BaseCard} from '@app/components/common/BaseCard/BaseCard';

export const HoseholdsCalculatorForm: React.FC = () => {
  const {t} = useTranslation();
  const {country} = useParams();

  const [isLoading, setLoading] = useState(false);
  const [calculationCompleted, setCalculationCompleted] = useState(false);
  const [calculationData, setCalculationData] = useState<RSHouseholdResponseModel | null>(null);

  const [roofsData, setRoofsData] = useState([{azimuthAngle: 'none', tiltAngle: 'none', freeRoofArea: 'none'}]);
  const [includeRoofSorting, setIncludeRoofSorting] = useState<boolean>(false);

  const [selectedExcelLanguage, setSelectedExcelLanguage] = useState<eExcelReportLanguageOptions>(
    eExcelReportLanguageOptions.ENGLISH,
  );

  const initialValues = {
    geolocationCoordinates: '44.787197, 20.457273',
    roofsData: [{azimuthAngle: '30', tiltAngle: '20', freeRoofArea: '48'}],
    annualConsumption: '4000',
    modulePower: '50',
    moduleLength: '30',
    moduleWidth: '200',
    investmentCost: '60000',
    roofType: 'hydro-isolation',
    higherTariffShare: '80',
  };

  const formItemLayout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    if (!validateCoordinates(values.latitude, values.longitude)) {
      notificationController.error({message: t('calculators.notifications.errors.geolocationError')});
      return;
    }

    switch (country) {
      case 'rs':
        return calculateRS(values);
      case 'global':
        return calculateGlobal(values);
      default:
        return renderGlobalCalculator();
    }
  };

  const calculateRS = (values: any) => {
    const data: RSHouseholdRequest = {
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
      includeRoofSorting: includeRoofSorting,
      roofs: roofsData.map((roof) => ({
        azimuthAngle: parseInt(roof.azimuthAngle, 10),
        inclinationAngle: parseInt(roof.tiltAngle, 10),
        freeRoofArea: parseInt(roof.freeRoofArea, 10),
      })),
      annualConsumption: parseInt(values.annualConsumption),
      modulePower: parseInt(values.modulePower),
      moduleLength: parseInt(values.moduleLength),
      moduleWidth: parseInt(values.moduleWidth),
      investmentCost: parseInt(values.investmentCost),
      roofType: RoofType[values.roofType as keyof typeof RoofType],
      higherTariffShare: parseInt(values.higherTariffShare) / 100,
    };

    calculateHouseholdDataForRS(data)
      .then((res) => {
        setLoading(false);
        setCalculationCompleted(true);
        setCalculationData(res);

        notificationController.success({message: t('calculators.notifications.success.calculationSuccess')});
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notificationController.error({message: t('calculators.notifications.errors.calculatingError')});
      });
  };

  const calculateGlobal = (values: any) => {
    const data: HouseholdGlobalRequestModel = {
      // Geolocation Coordinates
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),

      // Roofs Data
      roofs: roofsData.map((roof) => ({
        azimuthAngle: parseInt(roof.azimuthAngle, 10),
        inclinationAngle: parseInt(roof.tiltAngle, 10),
        freeRoofArea: parseInt(roof.freeRoofArea, 10),
      })),
      includeRoofSorting: includeRoofSorting,

      // Monthly Consumption Data
      monthlyConsumption: {
        january: parseInt(values.januaryElectricityConsumption),
        february: parseInt(values.februaryElectricityConsumption),
        march: parseInt(values.marchElectricityConsumption),
        april: parseInt(values.aprilElectricityConsumption),
        may: parseInt(values.mayElectricityConsumption),
        june: parseInt(values.juneElectricityConsumption),
        july: parseInt(values.julyElectricityConsumption),
        august: parseInt(values.augustElectricityConsumption),
        september: parseInt(values.septemberElectricityConsumption),
        october: parseInt(values.octoberElectricityConsumption),
        november: parseInt(values.novemberElectricityConsumption),
        december: parseInt(values.decemberElectricityConsumption),
      },
      maximumAuthorizedPower: parseInt(values.maximumAuthorizedPower),

      // Panels information
      modulePower: parseInt(values.modulePower),
      moduleLength: parseInt(values.moduleLength),
      moduleWidth: parseInt(values.moduleWidth),

      // Industry Electricity Data
      basePriceOfElectricity: parseInt(values.basePriceOfElectricity),
      gridFee: parseInt(values.additionalGridFeesAndTaxes) / 100,
      priceOfExportedElectricity: parseInt(values.sellingPriceOfElectricity),

      // Roof Type
      roofType: RoofType[values.roofType as keyof typeof RoofType],

      // Investment Cost
      investmentCost: parseInt(values.investmentCost),
    };

    calculateHouseholdDataGlobal(data)
      .then((res) => {
        setLoading(false);
        setCalculationCompleted(true);
        setCalculationData(res);

        notificationController.success({message: t('calculators.notifications.success.calculationSuccess')});
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notificationController.error({message: t('calculators.notifications.errors.calculatingError')});
      });
  };

  const getExcelReport = () => {
    setLoading(true);

    if (!calculationData) {
      setLoading(false);
      notificationController.error({message: t('calculators.notifications.errors.startingExcelGenerationError')});
      return;
    }

    let selectedCountry = eExcelReportCountryOptions.GLOBAL;

    console.log('selectedExcelLanguage', selectedExcelLanguage);

    switch (country) {
      case 'rs':
        selectedCountry = eExcelReportCountryOptions.RS;
        generateExcelReportRS(calculationData, selectedExcelLanguage)
          .then((res) => {
            openLinkInNewTab(res);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            notificationController.error({message: t('calculators.notifications.errors.startingExcelGenerationError')});
          });
        break;
      case 'global':
        selectedCountry = eExcelReportCountryOptions.GLOBAL;
        generateExcelReportGlobal(calculationData, eExcelReportLanguageOptions.ENGLISH)
          .then((res) => {
            openLinkInNewTab(res);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            notificationController.error({message: t('calculators.notifications.errors.startingExcelGenerationError')});
          });
        break;
    }
  };

  const renderCalculator = () => {
    switch (country) {
      case 'rs':
        return renderRSCalculator();
      case 'global':
        return renderGlobalCalculator();
      default:
        return renderGlobalCalculator();
    }
  };

  const renderRSCalculator = () => (
    <BaseButtonsForm
      {...formItemLayout}
      isFieldsChanged={true}
      // onFieldsChange={() => setFieldsChanged(true)}
      // initialValues={initialValues}
      name="validateForm"
      footer={
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.submit')}
          </BaseButton>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={14} style={{display: 'flex'}}>
          <BaseRow gutter={[30, 30]}>
            {/* Geolocation Coordinates */}
            <BaseCol span={24}>
              <GeolocationCoordinates />
            </BaseCol>

            {/* Roofs Data  */}
            <BaseCol span={24}>
              <RoofsDataInput
                roofsData={roofsData}
                setRoofsData={setRoofsData}
                includeRoofSorting={includeRoofSorting}
                setIncludeRoofSorting={setIncludeRoofSorting}
              />
            </BaseCol>

            {/* Panels information  */}
            <BaseCol span={24}>
              <PanelsInformationInput />
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xs={24} sm={24} xl={10} style={{display: 'flex'}}>
          <BaseRow gutter={[30, 30]}>
            {/* Annual Electricity Consumption  */}
            <BaseCol span={24}>
              <ElectricityConsumptionInput />
            </BaseCol>

            {/* Roof Type  */}
            <BaseCol span={24}>
              <RoofTypeInput />
            </BaseCol>

            {/* Investment Cost  */}
            <BaseCol span={24}>
              <InvestmentCostInput />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseButtonsForm>
  );

  const renderGlobalCalculator = () => (
    <BaseButtonsForm
      {...formItemLayout}
      isFieldsChanged={true}
      // initialValues={initialValues}
      // onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      footer={
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.submit')}
          </BaseButton>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} sm={24} xl={14} style={{display: 'flex'}}>
          <BaseRow gutter={[30, 30]}>
            {/* Geolocation Coordinates */}
            <BaseCol span={24}>
              <GeolocationCoordinates />
            </BaseCol>

            {/* Roofs Data  */}
            <BaseCol span={24}>
              <RoofsDataInput
                roofsData={roofsData}
                setRoofsData={setRoofsData}
                includeRoofSorting={includeRoofSorting}
                setIncludeRoofSorting={setIncludeRoofSorting}
              />
            </BaseCol>

            {/* Monthly Consumption Data  */}
            <BaseCol span={24}>
              <IndustryMonthlyElectricityInput />
            </BaseCol>
          </BaseRow>
        </BaseCol>

        <BaseCol xs={24} sm={24} xl={10} style={{display: 'flex'}}>
          <BaseRow gutter={[30, 30]}>
            {/* Panels information  */}
            <BaseCol span={24}>
              <PanelsInformationInput />
            </BaseCol>

            {/* Industry Electricity Data  */}
            <BaseCol span={24}>
              <IndustryElectricityDataInput />
            </BaseCol>

            {/* Roof Type  */}
            <BaseCol span={24}>
              <RoofTypeInput />
            </BaseCol>

            {/* Investment Cost  */}
            <BaseCol span={24}>
              <InvestmentCostInput />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseButtonsForm>
  );

  return (
    <>
      {renderCalculator()}

      {calculationData && (
        <div
          style={{
            float: 'right',
            padding: '15px',
            background: '#ffffff',
            fontSize: '16px',
            borderRadius: '7px',
          }}
        >
          <BaseRow gutter={[30, 30]}>
            {country === 'rs' && (
              <BaseCol xs={12} sm={12} xl={12}>
                <div>
                  <label>{t('excel.choseLanguage')}</label>
                  <Select
                    style={{width: '100%'}}
                    options={[
                      {value: eExcelReportLanguageOptions.SERBIAN, label: t('languages.serbian')},
                      {value: eExcelReportLanguageOptions.ENGLISH, label: t('languages.english')},
                    ]}
                    onChange={(value) => setSelectedExcelLanguage(value as eExcelReportLanguageOptions)}
                  />
                </div>
              </BaseCol>
            )}
            <BaseCol xs={12} sm={12} xl={12}>
              <BaseButton severity="warning" onClick={() => getExcelReport()} loading={isLoading}>
                {t('excel.exportButton')}
              </BaseButton>
            </BaseCol>
          </BaseRow>

          {/* 
          <BaseButton severity="info" onClick={() => setIsResultsModalVisible(true)}>
                  Pogledaj rezultate
                </BaseButton> 
                */}
        </div>
      )}

      <Col span={24}>
        {/* <Button type="primary" onClick={() => setIsResultsModalVisible(true)}>
                {t('modals.fullscreen')}
              </Button> */}
        <div className="households-calculator-buttons">
          {/* <Button type="ghost" onClick={clearForm} loading={isLoading}>
                  {t('solarCalculatorsPro.buttons.clear')}
                </Button> */}

          {/* <BaseButton type="primary" onClick={handleFormSubmit} loading={isLoading}>
                  {t('solarCalculatorsPro.buttons.submit')}
                </BaseButton> */}
        </div>
      </Col>

      {/* {calculationData && (
        <HouseholdsCalculatorResultsModal
          visible={isResultsModalVisible}
          onClose={() => setIsResultsModalVisible(false)}
          data={calculationData}
        />
      )} */}
    </>
  );
};
