import React from 'react';
import {ConfigProvider} from 'antd';
import {HelmetProvider} from 'react-helmet-async';
// import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import srRS from 'antd/lib/locale/sr_RS';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import {AppRouter} from './components/router/AppRouter';
import {useLanguage} from './hooks/useLanguage';
// import { useAutoNightMode } from './hooks/useAutoNightMode';
import {usePWA} from './hooks/usePWA';
import {useThemeWatcher} from './hooks/useThemeWatcher';
import {useAppSelector} from './hooks/reduxHooks';
import {themeObject} from './styles/themes/themeVariables';

const App: React.FC = () => {
  const {language} = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  // usePWA();

  // useAutoNightMode();

  // useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        {/* <ConfigProvider locale={srRS}> */}
        <ConfigProvider locale={language === 'en' ? enUS : srRS}>
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

export default App;
