import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';
import {DashboardCountriesSelect} from '@app/components/dashboard/DashboardCountriesSelect';

const DashboardPage: React.FC = () => {
  const {t} = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignContent: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '70vh',
        padding: '0 50px',
      }}
    >
      <PageTitle>{t('dashboard.pageTitle')}</PageTitle>
      <h1
        style={{
          fontSize: '30px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {t('dashboard.title')}
      </h1>
      <h2
        style={{
          textAlign: 'center',
        }}
      >
        <Trans i18nKey="dashboard.subtitle" />
      </h2>
      <DashboardCountriesSelect />
    </div>
  );
};

export default DashboardPage;
