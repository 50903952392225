import {FileExcelOutlined, PaperClipOutlined} from '@ant-design/icons';
import {Drawer} from 'antd';
import styled from 'styled-components';

export const ProjectDrawer = styled(Drawer)`
  min-width: 600px !important;

  .ant-drawer-title {
    font-size: 24px;
    color: #d3d3d3;
  }
`;

export const Heading = styled.h2`
  font-size: 30px;
  font-weight: 600;
`;

export const GroupValues = styled.div`
  margin-bottom: 20px;
`;

export const Value = styled.p`
  font-size: 20px;
`;

export const AttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const AttachmentHeader = styled.div`
  flex-basis: 100%;
  margin-bottom: 20px;
`;

export const AttachmentIcon = styled(PaperClipOutlined)`
  font-size: 30px;
`;

export const AttachmentHeading = styled.span`
  font-size: 20px;
`;

export const AttachmentBody = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const AttachmentItem = styled.div`
  border: 2px solid #e3e4e8;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const DocumentIcon = styled(FileExcelOutlined)`
  font-size: 30px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
