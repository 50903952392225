import {NotificationType} from '@app/components/common/BaseNotification/BaseNotification';
import {Priority} from '@app//constants/enums/priorities';
import visa from '@app/assets/images/card-issuers/visa.png';
import mastercard from '@app/assets/images/card-issuers/mastercard.png';
import maestro from '@app/assets/images/card-issuers/maestro.png';
import {CurrencyTypeEnum, Severity} from '@app/interfaces/interfaces';
import {BaseBadgeProps} from '@app/components/common/BaseBadge/BaseBadge';
import {currencies} from '@app/constants/config/currencies';
import i18n from 'i18next';

export const splitCoordinates = (coordString: any): {latitude: number; longitude: number} => {
  const parts = coordString.split(',').map((part: any) => parseFloat(part.trim()));
  return {
    latitude: parts[0],
    longitude: parts[1],
  };
};

export const validateCoordinates = (latitude: number, longitude: number): boolean => {
  const latitudeRegex = /^[-]?((([0-8]?[0-9])(\.(\d{1,8}))?)|(90(\.0+)?))$/;
  const isValidLatitude = latitudeRegex.test(latitude.toString());

  const longitudeRegex = /^[-]?((((1[0-7][0-9])|([0-9]?[0-9]))(\.(\d{1,8}))?)|180(\.0+)?)$/;
  const isValidLongitude = longitudeRegex.test(longitude.toString());

  return isValidLatitude && isValidLongitude;
};

export const openLinkInNewTab = (url: URL): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const shortenText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }

  let shortened = text.slice(0, maxLength);

  // Find the last space in the shortened string
  const lastSpaceIndex = shortened.lastIndexOf(' ');

  // If a space was found, cut the string there
  if (lastSpaceIndex > 0) {
    shortened = shortened.slice(0, lastSpaceIndex);
  }

  return shortened;
};

export function degToDMS(deg: number): string {
  let d = Math.floor(deg);
  const minfloat = (deg - d) * 60;
  let m = Math.floor(minfloat);
  const secfloat = (minfloat - m) * 60;
  let s = Math.round(secfloat);
  if (s === 60) {
    m++;
    s = 0;
  }
  if (m === 60) {
    d++;
    m = 0;
  }
  return `${d}° ${m}' ${s}"`;
}

export function getMonths(): string[] {
  return [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];
}

export function getDaysInWeek(): string[] {
  return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
}

export function getDaysInMonth(month: string): number {
  switch (month) {
    case 'january':
      return 31;
    case 'february':
      return 28;
    case 'march':
      return 31;
    case 'april':
      return 30;
    case 'may':
      return 31;
    case 'june':
      return 30;
    case 'july':
      return 31;
    case 'august':
      return 31;
    case 'september':
      return 30;
    case 'october':
      return 31;
    case 'november':
      return 30;
    case 'december':
      return 31;
    default:
      return 0;
  }
}

export function countWorkingHours(fromHours: number, fromMinutes: number, toHours: number, toMinutes: number): number {
  if (fromHours < 6) {
    fromHours = 6;
    fromMinutes = 0;
  }

  if (toHours > 22) {
    toHours = 22;
    toMinutes = 0;
  }

  let hoursDiff = toHours - fromHours;

  if (fromMinutes > toMinutes) {
    hoursDiff--;
    toMinutes += 60;
  }

  const minutesDiff = toMinutes - fromMinutes;

  return hoursDiff + minutesDiff / 60;
}

export function prepareDataForCashFlowDiagram(data: number[]): {EUR: number; year: number}[] {
  const out = [];
  const thisYear = new Date().getFullYear();
  for (let i = 0; i < data.length; i++) {
    out.push({EUR: data[i], year: thisYear + i});
  }
  return out;
}

export function roundNumber(number: number, decimals: number): number {
  return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export function getTodayDate(): string {
  const date = new Date();

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

// return date in format 'DD.MM.YYYY'
export const formatDate = (date: Date | string): string => {
  date = new Date(date);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const renderSchedules = (numberOfDays: number): string => {
  console.log('i18n', i18n.language);

  switch (i18n.language) {
    case 'rs':
      if (numberOfDays === 1) {
        return 'Svaki dan';
      }

      if (numberOfDays === 7) {
        return 'Svake nedelje';
      }

      if (numberOfDays === 30) {
        return 'Svakog meseca';
      }

      if (numberOfDays == 30) {
        return `Svaki meseci`;
      }

      if (numberOfDays > 30 && numberOfDays < 365 && numberOfDays % 30 === 0) {
        return `Svakih ${numberOfDays / 30} meseci`;
      }

      if (numberOfDays == 365) {
        return `Svake godine`;
      }

      if (numberOfDays >= 365 && numberOfDays % 365 === 0) {
        return `Svakih ${numberOfDays / 365} godina`;
      }

      return `Svakih ${numberOfDays} dana`;
    case 'en':
      if (numberOfDays === 1) {
        return 'Every day';
      }

      if (numberOfDays === 7) {
        return 'Every week';
      }

      if (numberOfDays === 30) {
        return 'Every month';
      }

      if (numberOfDays > 30 && numberOfDays < 365 && numberOfDays % 30 === 0) {
        return `Every ${numberOfDays / 30} months`;
      }

      if (numberOfDays == 365) {
        return `Every year`;
      }

      if (numberOfDays >= 365 && numberOfDays % 365 === 0) {
        return `Every ${numberOfDays / 365} years`;
      }

      return `Every ${numberOfDays} days`;
    default:
      return '';
  }
};

export const getCurrencyPrice = (price: number | string, currency: CurrencyTypeEnum, isIcon = true): string => {
  const currencySymbol = currencies[currency][isIcon ? 'icon' : 'text'];

  return isIcon ? `${currencySymbol}${price}` : `${price} ${currencySymbol}`;
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): {h: number; s: number; l: number} => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const msToH = (ms: number): number => Math.floor(ms / 3600000);

export const hToMS = (h: number): number => h * 3600000;

export const getPaymentCardTypeIcon = (type: string): string | null => {
  switch (type) {
    case 'visa':
      return visa;
    case 'mastercard':
      return mastercard;
    case 'maestro':
      return maestro;
    case 'amex':
      return 'amex';
    case 'discover':
      return 'discover';
    case 'diners':
      return 'diners';
    case 'jcb':
      return 'jcb';
    case 'unionpay':
      return 'unionpay';
    default:
      return null;
  }
};

export const mapBadgeStatus = (status: BaseBadgeProps['status']): Severity => {
  if (!status || status === 'default' || status === 'processing') {
    return 'info';
  }

  return status;
};
