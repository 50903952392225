import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import NumericInput from '../common/inputs/NumericInput/NumericInput';

export const IndustryMonthlyElectricityInput: React.FC = () => {
  const {t} = useTranslation();

  const [januaryConsumption, setJanuaryConsumption] = React.useState<string>('');
  const [februaryConsumption, setFebruaryConsumption] = React.useState<string>('');
  const [marchConsumption, setMarchConsumption] = React.useState<string>('');
  const [aprilConsumption, setAprilConsumption] = React.useState<string>('');
  const [mayConsumption, setMayConsumption] = React.useState<string>('');
  const [juneConsumption, setJuneConsumption] = React.useState<string>('');
  const [julyConsumption, setJulyConsumption] = React.useState<string>('');
  const [augustConsumption, setAugustConsumption] = React.useState<string>('');
  const [septemberConsumption, setSeptemberConsumption] = React.useState<string>('');
  const [octoberConsumption, setOctoberConsumption] = React.useState<string>('');
  const [novemberConsumption, setNovemberConsumption] = React.useState<string>('');
  const [decemberConsumption, setDecemberConsumption] = React.useState<string>('');

  const [maximumAuthorizedPower, setMaximumAuthorizedPower] = React.useState<string>('');

  return (
    <CalculatorsInputCard
      id="industryMonthlyElectricityConsumption"
      title={t('calculators.inputData.industryMonthlyElectricityConsumption.title')}
      description={t('calculators.inputData.industryMonthlyElectricityConsumption.description')}
    >
      <div
        style={{
          gridArea: 'electricityBillInputAreaGrid',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: '10px 20px',
          gap: '10px 20px',
          marginBottom: '20px',
        }}
      >
        <BaseButtonsForm.Item
          name="januaryElectricityConsumption"
          label={t('months.january')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={januaryConsumption} onChange={setJanuaryConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="februaryElectricityConsumption"
          label={t('months.february')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={februaryConsumption} onChange={setFebruaryConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="marchElectricityConsumption"
          label={t('months.march')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={marchConsumption} onChange={setMarchConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="aprilElectricityConsumption"
          label={t('months.april')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={aprilConsumption} onChange={setAprilConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="mayElectricityConsumption"
          label={t('months.may')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={mayConsumption} onChange={setMayConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="juneElectricityConsumption"
          label={t('months.june')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={juneConsumption} onChange={setJuneConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="julyElectricityConsumption"
          label={t('months.july')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={julyConsumption} onChange={setJulyConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="augustElectricityConsumption"
          label={t('months.august')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={augustConsumption} onChange={setAugustConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="septemberElectricityConsumption"
          label={t('months.september')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={septemberConsumption} onChange={setSeptemberConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="octoberElectricityConsumption"
          label={t('months.october')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={octoberConsumption} onChange={setOctoberConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="novemberElectricityConsumption"
          label={t('months.november')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={novemberConsumption} onChange={setNovemberConsumption} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="decemberElectricityConsumption"
          label={t('months.december')}
          rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
        >
          <NumericInput min={0} suffix="kWh" value={decemberConsumption} onChange={setDecemberConsumption} />
        </BaseButtonsForm.Item>
      </div>
      <BaseButtonsForm.Item
        name="maximumAuthorizedPower"
        label={t('calculators.inputData.industryMonthlyElectricityConsumption.maximumAuthorizedPower')}
        rules={[{required: true, message: t('calculators.inputData.validation.required')}]}
      >
        <NumericInput min={0} suffix="kW" value={maximumAuthorizedPower} onChange={setMaximumAuthorizedPower} />
      </BaseButtonsForm.Item>
    </CalculatorsInputCard>
  );
};
