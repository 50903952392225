import React from 'react';
import * as S from './References.styles';
import {FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined} from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        &copy; {new Date().getFullYear()}.{' '}
        <a href="#" target="_blank" rel="noreferrer">
          Software.{' '}
        </a>
        {/* All rights reserved{' '} */}
        Powered by{' '}
        <a href="https://solarlink.app/" target="_blank" rel="noreferrer">
          Solarlink.
        </a>
      </S.Text>
      <S.Icons>
        {/* <a href="https://github.com/altence/lightence-admin" target="_blank" rel="noreferrer">
          <GithubOutlined />
        </a> */}
        <a href="https://www.linkedin.com/company/solarlinkapp" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a>
        <a href="https://www.facebook.com/groups/altence" target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
        <a href="https://www.instagram.com/solarlink.app" target="_blank" rel="noreferrer">
          <InstagramOutlined />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
