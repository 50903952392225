import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const H3 = styled.h3`
  color: #262626;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const P = styled.p`
  font-size: 18px;
  font-weight: 400;
  // line-height: 20px;
  color: #666666;

  &.small {
    font-size: 14px;
  }
`;

export const GoCorner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
`;

export const GoArrow = styled.div`
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
`;

export const Card1 = styled.a`
  display: block;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }

  &:hover {
    p {
      transition: all 0.3s ease-out;
      color: rgba(255, 255, 255, 0.8);
    }
    h3 {
      transition: all 0.3s ease-out;
      color: #ffffff;
    }
  }
`;

export const Card2 = styled.a`
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
`;

export const Card3 = styled.a`
  display: block;
  top: 0px;
  position: relative;
  max-width: 400px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #f2f8f9;

  .go-corner {
    opacity: 0.7;
  }

  &:hover {
    border: 1px solid #00838d;
    box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
    z-index: 500;
  }

  &:hover {
    p {
      color: #00838d;
    }
    .go-corner {
      transition: opacity 0.3s linear;
      opacity: 1;
    }
  }
`;

export const Card4 = styled.a`
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #cccccc;

  .go-corner {
    background-color: #00838d;
    height: 100%;
    width: 16px;
    padding-right: 9px;
    border-radius: 0;
    transform: skew(6deg);
    margin-right: -36px;
    align-items: start;
    background-image: linear-gradient(-45deg, #8f479a 1%, #dc2a74 100%);
  }

  .go-arrow {
    transform: skew(-6deg);
    margin-left: -2px;
    margin-top: 9px;
    opacity: 0;
  }

  &:hover {
    border: 1px solid #cd3d73;

    .go-corner {
      margin-right: -12px;
    }

    .go-arrow {
      opacity: 1;
    }
  }

  h3 {
    margin-top: 8px;
  }
`;
