import React, {useState} from 'react';
import {Input} from 'antd';

interface NumericInputProps {
  style?: React.CSSProperties;
  value: string;
  suffix?: string;
  min?: number;
  max?: number;
  onChange: (value: string) => void;
}

const NumericInput = (props: NumericInputProps) => {
  const {value, min, max, onChange} = props;
  const [lastValidInput, setLastValidInput] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value: inputValue} = e.target;
    let valid = false;

    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue))
      if ((min === undefined || Number(inputValue) >= min) && (max === undefined || Number(inputValue) <= max))
        valid = true;

    if (valid) {
      setLastValidInput(inputValue);
      onChange(inputValue);
    } else onChange(lastValidInput);
  };

  return <Input {...props} onChange={handleChange} />;
};

export default NumericInput;
