import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
  tokenExpiration,
  loggedInUser,
} from '@app/api/auth.api';
import {setUser} from '@app/store/slices/userSlice';
import {deleteToken, deleteUser, persistToken, readToken} from '@app/services/localStorage.service';

const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, {dispatch}) =>
  login(loginPayload)
    .then((res) => {
      persistToken(res.token.toString());
      return res.token;
    })
    .catch((error) => {
      throw new Error("User with given username and password doesn't exist");
    }),
);

export const checkTokenExpiration = createAsyncThunk('auth/checkTokenExpiration', async (payload, {dispatch}) => {
  const token = readToken();

  if (token == null || token == undefined || token == '' || token == 'bearerToken') {
    deleteToken();
    deleteUser();
    dispatch(setUser(null));
    throw new Error('Your session token has expired');
  }

  tokenExpiration()
    .then((res) => {
      if (res == 200) {
        return true;
      } else {
        alert('Token expired');
        deleteToken();
        deleteUser();
        dispatch(setUser(null));
        throw new Error('Token expired');
      }
    })
    .catch((error) => {
      deleteToken();
      deleteUser();
      dispatch(setUser(null));
      throw new Error('Your session token has expired');
    });
});

export const setLoggedInUser = createAsyncThunk('auth/setLoggedInUser', async (payload, {dispatch}) =>
  loggedInUser()
    .then((res) => {
      // const user = {
      //   id: res.id,
      //   firstName: res.firstname,
      //   lastName: res.lastname,
      //   imgUrl:
      //     'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg',
      //   userName: res.username,
      //   email: {
      //     name: res.email,
      //     verified: false,
      //   },
      //   phone: {
      //     number: res.phoneNumber,
      //     verified: true,
      //   },
      //   sex: 'male',
      //   birthday: '01/26/1985',
      //   lang: 'en',
      //   country: 'RS',
      //   city: 'Beograd',
      //   address1: 'Beogradska 1',
      //   zipcode: 11000,
      //   website: 'smartmaintenance.rs',
      //   socials: {
      //     twitter: '@smartmaintenance.rs',
      //     facebook: 'https://facebook.com/groups/1076577369582221',
      //     linkedin: 'https://linkedin.com/company/altence',
      //   },
      // };
      // res['imgUrl'] =
      //   'https://static.vecteezy.com/system/resources/previews/009/292/244/original/default-avatar-icon-of-social-media-user-vector.jpg';
      // dispatch(setUser(res));
    })
    .catch((error) => {
      deleteToken();
      deleteUser();
      dispatch(setUser(null));
      throw new Error('User not found');
    }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

export const doSetNewPassword = createAsyncThunk('auth/doSetNewPassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, {dispatch}) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
