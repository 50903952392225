import React from 'react';

import HomeIcon from '@mui/icons-material/Home';
import FactoryIcon from '@mui/icons-material/Factory';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  type?: 'divider';
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.home',
    key: 'home',
    icon: <HomeIcon />,
  },
  {
    title: '',
    key: '',
    type: 'divider',
  },
  {
    title: 'common.householdsCalculator',
    key: 'households',
    // url: '/households-calculator',
    icon: <MapsHomeWorkIcon />,
    children: [
      {
        title: 'countries.global',
        key: 'householdsGlobal',
        url: process.env.REACT_APP_ROUTE_HOUSEHOLDS_CALCULATOR + '/global',
      },
      {
        title: 'countries.serbia',
        key: 'householdsSerbia',
        url: process.env.REACT_APP_ROUTE_HOUSEHOLDS_CALCULATOR + '/rs',
      },
    ],
  },
  {
    title: 'common.industryCalculator',
    key: 'industry',
    // url: '/industry-calculator',
    icon: <FactoryIcon />,
    children: [
      {
        title: 'countries.global',
        key: 'industryGlobal',
        url: process.env.REACT_APP_ROUTE_INDUSTRY_CALCULATOR + '/global',
      },
      {
        title: 'countries.serbia',
        key: 'industrySerbia',
        url: process.env.REACT_APP_ROUTE_INDUSTRY_CALCULATOR + '/rs',
      },
    ],
  },
];
