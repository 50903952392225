import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import * as React from 'react';
import * as S from './PageLayout.styles';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ArrowLeftOutlined} from '@ant-design/icons';
export interface IPageLayoutProps {
  title: string;
  children: React.ReactNode;
  pageOptions?: React.ReactNode;
  backButtonLink?: string;
}

/**
 * PageLayout component renders a page layout with a header, options, and content.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the page.
 * @param {React.ReactNode} props.children - The content of the page.
 * @param {React.ReactNode} props.pageOptions - The options to be displayed on the page.
 * @param {boolean} [props.backButton=true] - Determines whether to display a back button or not. Default is true.
 * @returns {React.ReactElement} The rendered PageLayout component.
 */
export const PageLayout: React.FC<IPageLayoutProps> = (props: IPageLayoutProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <S.PageHeader>
        <S.PageTitle>
          <S.BackButtonContainer>
            {props.backButtonLink && (
              <>
                <S.BackButton
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  size="middle"
                  onClick={() => navigate(props.backButtonLink!)}
                >
                  {t('common.back')}
                </S.BackButton>
                <S.VerticalHr />
              </>
            )}
          </S.BackButtonContainer>
          <h1 style={{fontSize: '32px', fontWeight: '700', margin: '0'}}>{props.title}</h1>
        </S.PageTitle>

        <S.PageOptions>{props.pageOptions}</S.PageOptions>
      </S.PageHeader>

      <S.Hr />

      <S.PageContent>{props.children}</S.PageContent>
    </>
  );
};
