import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '@app/components/common/PageTitle/PageTitle';
import {PageLayout} from '@app/components/layouts/PageLayout/PageLayout';

import {HoseholdsCalculatorForm} from '@app/components/calculators/forms/HoseholdsCalculatorForm';
import {useParams} from 'react-router-dom';

const HoseholdsCalculatorPage: React.FC = () => {
  const {t} = useTranslation();
  const {country} = useParams();

  const getCountry = () => {
    switch (country) {
      case 'rs':
        return t('countries.serbia');
      case 'global':
        return t('countries.global');
      default:
        return t('countries.global');
    }
  };

  return (
    <>
      <PageTitle>{t('householdsCalculator.pageHeader')}</PageTitle>
      <PageLayout
        title={t('householdsCalculator.pageHeader') + ' - ' + getCountry()}
        backButtonLink={process.env.REACT_APP_ROUTE_HOME}
      >
        <HoseholdsCalculatorForm />
      </PageLayout>
    </>
  );
};

export default HoseholdsCalculatorPage;
