import * as React from 'react';
import TextField from '@mui/material/TextField';
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Button, Checkbox, FormGroup, FormControlLabel} from '@mui/material';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import './WorkingHoursInput.css';

const locale = 'en-gb';

interface WorkingHoursInputProps {
  // setWorkingHours: (workingHours: Record<string, string>) => void;
  // workingHours: Record<string, string>;
  setWorkingHours: React.Dispatch<
    React.SetStateAction<{
      monday: string;
      tuesday: string;
      wednesday: string;
      thursday: string;
      friday: string;
      saturday: string;
      sunday: string;
    }>
  >;
  workingHours: {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
  };
}

interface WorkingHoursInputElementProps {
  day: string;
  workingHours: {
    from: dayjs.Dayjs | null;
    to: dayjs.Dayjs | null;
  };
  handleWorkingHoursChange: (newValue: dayjs.Dayjs | null, day: string, fromOrTo: 'from' | 'to') => void;
}

const getTimeInFormat = (date: dayjs.Dayjs): string => {
  const hours = String(date.get('hour')).padStart(2, '0');
  const minutes = String(date.get('minute')).padStart(2, '0');
  return `${hours}${minutes}`;
};

const WorkingHoursInput: React.FC<WorkingHoursInputProps> = (props) => {
  const {t} = useTranslation();

  const [displayAllDays, setDisplayAllDays] = React.useState(false);
  const [timeFrom, setTimeFrom] = React.useState<dayjs.Dayjs | null>(null);
  const [timeTo, setTimeTo] = React.useState<dayjs.Dayjs | null>(null);

  const [tempWorkingHours, setTempWorkingHours] = React.useState<any>({
    monday: {
      from: null,
      to: null,
    },
    tuesday: {
      from: null,
      to: null,
    },
    wednesday: {
      from: null,
      to: null,
    },
    thursday: {
      from: null,
      to: null,
    },
    friday: {
      from: null,
      to: null,
    },
    saturday: {
      from: null,
      to: null,
    },
    sunday: {
      from: null,
      to: null,
    },
  });

  const handleDisplayAllDays = () => {
    setDisplayAllDays(!displayAllDays);
  };

  const handleWorkingHoursChange2 = (newValue: dayjs.Dayjs | null, day: string, fromOrTo: 'from' | 'to') => {
    let formattedTimeValue: string | null = null;

    const from = fromOrTo === 'from' ? newValue : timeFrom;
    const to = fromOrTo === 'to' ? newValue : timeTo;

    if (fromOrTo === 'from' && to) {
      formattedTimeValue = `${getTimeInFormat(newValue!)}-${getTimeInFormat(to)}`;
      setTimeFrom(newValue);
    }

    if (fromOrTo === 'to' && from) {
      formattedTimeValue = `${getTimeInFormat(from)}-${getTimeInFormat(newValue!)}`;
      setTimeTo(newValue);
    }

    if (!formattedTimeValue) return;

    if (day === 'workingWeek') {
      props.setWorkingHours({
        ...props.workingHours,
        monday: formattedTimeValue,
        tuesday: formattedTimeValue,
        wednesday: formattedTimeValue,
        thursday: formattedTimeValue,
        friday: formattedTimeValue,
      });
    } else {
      props.setWorkingHours({
        ...props.workingHours,
        [day]: formattedTimeValue,
      });
    }
  };

  const handleWorkingHoursChange = (newValue: dayjs.Dayjs | null, day: string, fromOrTo: 'from' | 'to') => {
    console.log('newValue', newValue);
    console.log('day', day);
    console.log('fromOrTo', fromOrTo);

    const workingWeekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

    if (day === 'workingWeek') {
      const updatedDays = workingWeekDays.reduce((acc, currDay) => {
        return {
          ...acc,
          [currDay]: {
            ...tempWorkingHours[currDay],
            [fromOrTo]: newValue,
          },
        };
      }, {});

      setTempWorkingHours({
        ...tempWorkingHours,
        ...updatedDays,
      });
    } else {
      setTempWorkingHours({
        ...tempWorkingHours,
        [day]: {
          ...tempWorkingHours[day],
          [fromOrTo]: newValue,
        },
      });
    }

    if (day === 'workingWeek') {
      if (fromOrTo === 'from') {
        if (workingWeekDays.every((workingWeekDay) => tempWorkingHours[workingWeekDay].to)) {
          // alert('Whole working week has both from and to values');

          props.setWorkingHours({
            ...props.workingHours,
            monday: `${getTimeInFormat(newValue!)}-${getTimeInFormat(tempWorkingHours.monday.to!)}`,
            tuesday: `${getTimeInFormat(newValue!)}-${getTimeInFormat(tempWorkingHours.tuesday.to!)}`,
            wednesday: `${getTimeInFormat(newValue!)}-${getTimeInFormat(tempWorkingHours.wednesday.to!)}`,
            thursday: `${getTimeInFormat(newValue!)}-${getTimeInFormat(tempWorkingHours.thursday.to!)}`,
            friday: `${getTimeInFormat(newValue!)}-${getTimeInFormat(tempWorkingHours.friday.to!)}`,
          });
        }
      }
      if (fromOrTo === 'to') {
        if (workingWeekDays.every((workingWeekDay) => tempWorkingHours[workingWeekDay].from)) {
          // alert('Whole working week has both from and to values');
          props.setWorkingHours({
            ...props.workingHours,
            monday: `${getTimeInFormat(tempWorkingHours.monday.from!)}-${getTimeInFormat(newValue!)}`,
            tuesday: `${getTimeInFormat(tempWorkingHours.tuesday.from!)}-${getTimeInFormat(newValue!)}`,
            wednesday: `${getTimeInFormat(tempWorkingHours.wednesday.from!)}-${getTimeInFormat(newValue!)}`,
            thursday: `${getTimeInFormat(tempWorkingHours.thursday.from!)}-${getTimeInFormat(newValue!)}`,
            friday: `${getTimeInFormat(tempWorkingHours.friday.from!)}-${getTimeInFormat(newValue!)}`,
          });
        }
      }
    } else {
      if (fromOrTo === 'from') {
        if (tempWorkingHours[day].to) {
          // alert('Whole working week has both from and to values');
          props.setWorkingHours({
            ...props.workingHours,
            [day]: `${getTimeInFormat(newValue!)}-${getTimeInFormat(tempWorkingHours[day].to!)}`,
          });
        }
      }
      if (fromOrTo === 'to') {
        if (tempWorkingHours[day].from) {
          // alert('Whole working week has both from and to values');
          props.setWorkingHours({
            ...props.workingHours,
            [day]: `${getTimeInFormat(tempWorkingHours[day].from!)}-${getTimeInFormat(newValue!)}`,
          });
        }
      }
    }

    const formattedTimeValue: string | null = null;

    const from = fromOrTo === 'from' ? newValue : timeFrom;
    const to = fromOrTo === 'to' ? newValue : timeTo;

    console.log(tempWorkingHours);
  };

  return (
    <CalculatorsInputCard
      id="workingHours"
      title={t('calculators.inputData.workingHours.title')}
      description={t('calculators.inputData.workingHours.description')}
    >
      {displayAllDays ? (
        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map((day) => (
          <WorkingHoursInputElement
            key={day}
            day={day}
            // workingHours={props.workingHours[day]}
            workingHours={tempWorkingHours[day as keyof typeof tempWorkingHours]}
            handleWorkingHoursChange={handleWorkingHoursChange}
          />
        ))
      ) : (
        <WorkingHoursInputElement
          day={'workingWeek'}
          workingHours={tempWorkingHours.friday} // Consider updating this to reflect the correct initial state
          handleWorkingHoursChange={handleWorkingHoursChange}
        />
      )}
      <Button onClick={handleDisplayAllDays} style={{justifyContent: 'flex-end'}}>
        {t(displayAllDays ? 'common.collapse' : 'common.expand')}
      </Button>
      <WorkingHoursInputElement
        day={'saturday'}
        workingHours={tempWorkingHours.saturday}
        handleWorkingHoursChange={handleWorkingHoursChange}
      />
      <WorkingHoursInputElement
        day={'sunday'}
        workingHours={tempWorkingHours.sunday}
        handleWorkingHoursChange={handleWorkingHoursChange}
      />
    </CalculatorsInputCard>
  );
};

const WorkingHoursInputElement: React.FC<WorkingHoursInputElementProps> = (props) => {
  const {t} = useTranslation();

  const [dayOff, setDayOff] = React.useState(props.day === 'saturday' || props.day === 'sunday');

  return (
    <div className="working-hours-input-day" style={{marginBottom: '20px'}}>
      <div className="working-hours-input-day-name">
        <Checkbox checked={!dayOff} style={{padding: '0'}} onClick={() => setDayOff(!dayOff)} />
        <h5 style={{marginLeft: '10px'}}>{t(`daysOfTheWeek.${props.day}`)}</h5>
      </div>
      {!dayOff && (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <TimePicker
            label={t('common.from')}
            value={dayjs(props.workingHours.from)}
            ampm={false}
            minutesStep={30}
            onChange={(newValue: any) => props.handleWorkingHoursChange(newValue, props.day, 'from')}
          />
          <TimePicker
            label={t('common.to')}
            value={dayjs(props.workingHours.to)}
            ampm={false}
            minutesStep={30}
            onChange={(newValue: any) => props.handleWorkingHoursChange(newValue, props.day, 'to')}
          />
        </LocalizationProvider>
      )}
    </div>
  );
};

export default WorkingHoursInput;
