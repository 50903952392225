import styled from 'styled-components';
import {BaseSelect as CommonSelect} from '@app/components/common/selects/BaseSelect/BaseSelect';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {FONT_SIZE, FONT_WEIGHT} from '@app/styles/themes/constants';
import {BaseSteps} from '@app/components/common/BaseSteps/BaseSteps';
import {BaseDatePicker} from '@app/components/common/pickers/BaseDatePicker';

export const Row = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const Form = styled(BaseForm)`
  width: 100%;
`;

export const FormContent = styled.div`
  margin: 1.25rem 0.5rem;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
`;

export const FormGroupHeading = styled.h3`
  flex-basis: 100%;
`;

export const FormItem = styled(BaseForm.Item)``;

export const Hr = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #e7ebee;
  margin: 20px 0;
`;

export const BreakLine = styled.div`
  flex-basis: 100%;
  height: 0; /* Optional: Adjust height if needed */
`;
