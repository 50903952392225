import * as React from 'react';
import {BaseCard} from '../common/BaseCard/BaseCard';
import {useTranslation} from 'react-i18next';
import {BaseButtonsForm} from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseInput} from '../common/inputs/BaseInput/BaseInput';
import {CalculatorsInputCard} from './common/CalculatorsInputCard';
import GeolocationInput from '../common/inputs/GeolocationInput/GeolocationInput';
import NumericInput from '../common/inputs/NumericInput/NumericInput';

export const GeolocationCoordinates: React.FC = () => {
  const {t} = useTranslation();
  const [latitude, setLatitude] = React.useState('');
  const [longitude, setLongitude] = React.useState('');

  return (
    <CalculatorsInputCard
      id="geolocationCoordinates"
      title={t('calculators.inputData.geolocationCoordinates.title')}
      description={t('calculators.inputData.geolocationCoordinates.description')}
    >
      <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '15px'}}>
        <BaseButtonsForm.Item
          name="latitude"
          label={t('calculators.inputData.geolocationCoordinates.latitude')}
          rules={[
            {
              required: true,
              message: t('calculators.inputData.geolocationCoordinates.errors.latitude'),
            },
          ]}
        >
          <NumericInput value={latitude} onChange={setLatitude} />
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="longitude"
          label={t('calculators.inputData.geolocationCoordinates.longitude')}
          rules={[
            {
              required: true,
              message: t('calculators.inputData.geolocationCoordinates.errors.longitude'),
            },
          ]}
        >
          <NumericInput value={longitude} onChange={setLongitude} />
        </BaseButtonsForm.Item>
      </div>
    </CalculatorsInputCard>
  );
};
