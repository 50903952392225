import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {OpenProjectModel} from '@app/api/openProject.api';
import {FundProjectionScreenOutlined, PlusOutlined} from '@ant-design/icons';

import * as S from './CardOpenProject.styles';
import {BaseButton} from '@app/components/common/BaseButton/BaseButton';

export interface CardOpenProjectProps {
  openProjet: OpenProjectModel;
  onClick: (openProject: OpenProjectModel) => void;
}

export const CardOpenProject: React.FC<CardOpenProjectProps> = ({openProjet, onClick}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <S.Card onClick={() => onClick(openProjet)}>
        <S.CardHeader>
          <S.OpenProjectIcon />
          <S.CardHeaderGroup>
            <S.CardHeaderTitle>Open projet</S.CardHeaderTitle>
            <S.CardHeaderSubtitle>5 days left</S.CardHeaderSubtitle>
          </S.CardHeaderGroup>
        </S.CardHeader>

        <S.CardBody>
          <S.LocationAndCapacity>
            <S.LocationAndCapacityItem>
              <S.LocationAndCapacityItemValue>{openProjet.location}</S.LocationAndCapacityItemValue>
              <S.LocationAndCapacityItemDescription>Location</S.LocationAndCapacityItemDescription>
            </S.LocationAndCapacityItem>

            <S.LocationAndCapacityItem>
              <S.LocationAndCapacityItemValue>{openProjet.plantCapacity} kWp</S.LocationAndCapacityItemValue>
              <S.LocationAndCapacityItemDescription>Plant capacity</S.LocationAndCapacityItemDescription>
            </S.LocationAndCapacityItem>
          </S.LocationAndCapacity>

          <S.CardItemsGroup>
            <S.CardBodyItem>
              <S.RoofStructureIcon />
              <S.CardBodyItemValue>{openProjet.roofStructure}</S.CardBodyItemValue>
            </S.CardBodyItem>

            <S.CardBodyItem>
              <S.InvestmentTimelineIcon />
              <S.CardBodyItemValue>{openProjet.investmentTimeline}</S.CardBodyItemValue>
            </S.CardBodyItem>
          </S.CardItemsGroup>
        </S.CardBody>
      </S.Card>
    </>
  );
};
