import axios from 'axios';
import {AxiosError} from 'axios';
import {ApiError} from '@app/api/ApiError';
import {readToken} from '@app/services/localStorage.service';

export const httpLinkProApi = axios.create({
  baseURL: process.env.REACT_APP_LINK_PRO_API_BASE,
});

httpLinkProApi.interceptors.request.use((config) => {
  config.headers = {...config.headers, Authorization: `Bearer ${readToken()}`};

  return config;
});

httpLinkProApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});

export interface ApiErrorData {
  message: string;
}
